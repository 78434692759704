import React, {FC, useEffect, useState} from 'react'
// @ts-ignore
import styles from './CardSortingStyles.module.css'
import classNames from "classnames";
import {AddCardsControl} from "./components/AddCardsControl";
import {Sorting} from "./components/Sorting";
import {useTranslate} from "./translates/useTranslate";
import {InstructionBlock} from "../../../system/components";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {UserSoringStatus} from "./reducers/cardSortingReducer/cardCortingReducerTypes";
import {cardSortingSlice} from "./reducers/cardSortingReducer/cardSortingReducer";
import {Preloader} from "../../../system/UX";
import {useNavigate, useParams} from "react-router-dom";
import {getErrorUrl, getObjectFromLocalStorage} from "../../../../common/utils";
import {getSortingState} from "./http";

interface CardSortingProps {
	className?: string
}

export const CardSorting: FC<CardSortingProps> = (props) => {
	const { className } = props

	const {
		info,
		group_state,
		user_status,
	} = useAppSelector(state => state.cardSortingReducer)

	const [isLoading, setIsLoading] = useState(true)
	const translates = useTranslate()
	const dispatch = useAppDispatch()
	const { setState, setSortingState } = cardSortingSlice.actions
	const project_id = Number(useParams().project_id)
	const navigate = useNavigate()

	useEffect(() => {
		setIsLoading(true)
		getSortingState(project_id).then(data => {
			setIsLoading(false)
			dispatch(setSortingState(data))

			const projectDataObject = getObjectFromLocalStorage(String(project_id))
			if (projectDataObject.groupState) {
				dispatch(setState(projectDataObject.groupState))
			} else if (data.temp_json_state) {
				dispatch(setState(JSON.parse(data.temp_json_state)))
			}
		}).catch(e => {
			console.log(e.message)
			navigate(getErrorUrl(e.message))
		})
	}, [])

	return (
  		<div className={classNames(styles.cardSortingContainer, className)}>
			{isLoading ?
				<Preloader isFullPage={true}/>
				:
				<>
					{user_status === UserSoringStatus.COLLECTING_CARDS && info.question_list && <AddCardsControl
						maxCards={info.question_list.max_cards_per_question}
						minCards={info.question_list.min_cards_per_question}
					/>}

					{user_status === UserSoringStatus.WAITING_FOR_SORTING && <InstructionBlock
						title={translates.cardsNotCollectedTittle}
						content={translates.cardsNotCollectedText}
					/>}

					{(user_status === UserSoringStatus.DOING_SORT || user_status === UserSoringStatus.SORTING_NOT_STARTED)
						&& group_state && <Sorting />}

					{user_status === UserSoringStatus.SORTING_FINISHED && <InstructionBlock
						title={translates.sortingFinishedTittle}
						content={translates.sortingFinishedText}
					/>}
				</>
			}
		</div>
	)
}

export default CardSorting
