import {Translates} from "./translates";

export const ruTranslates: Translates = {
    gotItButton: 'Понятно',
    save: 'Сохранить',
    cardsNotCollectedTittle: 'Спасибо, мы получили ваши ответы!',
    cardsNotCollectedText: 'Однако часть других участников еще не прислала свои ответы. Как только они закончат, вы сможете перейти к следующему заданию. Мы пришлем приглашение на е-мэйл.',
    cardInputPlaceholder: 'Напишите текст карточки...',
    addCardButton: 'Добавить карточку',
    removeCardButton: 'Убрать карточку',
    trashMouseHint: 'Поместить в корзину',
    backMouseHint: 'Поместить в банк',
    hintNotification: 'Нажмите на эту иконку, чтобы посмотреть инструкцию',
    mobileHintNotification: 'Нажмите для появления инструкции',
    sendCardsButtonHint: 'Кнопка станет активной, когда вы заполните все карточки',
    deleteGroupHint: 'Удалить группу',
    confirmDeleteGroup: 'Вы уверены, что хотите удалить данную группу? Все карточки данной группы поместятся обратно в банк.',
    binHeader: 'Карточки с непонятным содержанием',
    sortingFinishedTittle: 'Спасибо, мы сохранили ваши ответы.',
    sortingFinishedText: '',
    group: 'Группа',
    groupCardCounter: 'карточек',
    groupRatedCardCounter: 'оценено',
    addGroupButton: 'Добавить группу',
    finishSortButton: 'Завершить сортировку',
    cardCounter: {
        sorted: 'Отсортировано ',
        of: ' из ',
        cards: ' карточек',
        liked: ' лайкнуто',
        disliked: ' дизлайкнуто'
    },
    yes: 'Да',
    no: 'Нет',
    sortingErrors: {
        notAllCardsSorted: 'Вы не отсортировали все карточки',
        emptyGroupName: 'Некоторые имена групп пустые. Дайте им имя',
        binNameUsed: 'bin - системное имя, пожалуйста, переименуйте',
        notAllLikesGiven: 'Вы не поставили нужное количество лайков',
        notAllDislikesGiven: 'Вы не поставили нужное количество дизлайков'
    },
    saveSortingConfirm: 'Вы действительно хотите сохранить сортировку?',
    setLikesRequest: 'Для того, чтобы сохранить сортировку, нужно проставить недостающие оценки.',
    groupContainerPlaceholder: 'Выберите карточки, схожие по смыслу, и нажмите «Добавить группу», чтобы создать группу и поместить их туда.',
    bankPlaceholder: 'Поздравляем! Вы отсортировали все карточки. Можете завершать сортировку.'
}