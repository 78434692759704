export interface CardSortingState {
    info: CardSortingInfo
    group_state: GroupState
    user_status: UserSoringStatus
    project_id: number
    isLoading: boolean
    fetchError: string
}

export interface GroupState {
    pickedCards: {
        group: 'bank' | number
        cards: number[]
    }
    groups: Group[]
    bank: Card[]
    bin: Card[]
    total_number_of_cards: number
}

export enum UserSoringStatus {
    COLLECTING_CARDS = 'collecting cards',
    WAITING_FOR_SORTING = 'waiting for sorting',
    SORTING_NOT_STARTED = 'sorting not started',
    DOING_SORT = 'doing sort',
    SORTING_FINISHED = 'sorting finished'
}

export interface CardSortingInfo {
    instruction_text: string
    instruction_title: string
    sorting_instruction_text: string
    mobile_sorting_instruction_text: string
    sorting_instruction_title: string
    like_hint: string
    dislike_hint: string
    neutral_grade_hint: string
    like_limit: number
    dislike_limit: number
    question_list?: QuestionList
}

interface QuestionList {
    max_cards_per_question: number
    min_cards_per_question: number
    questions: Question[]
}

interface Question {
    id: number,
    text: string
}

export interface Group {
    id: number
    name: string
    cards: Card[]
}

export type LikeStatus = 'liked' | 'disliked' | 'neutral'

export type GradeStatus = LikeStatus | 'unset'

export interface Card {
    id: number
    text: string
    grade_status: GradeStatus
}