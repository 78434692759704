import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from './CardSortingAdminStyles.module.css'
import {CardSortingSettings} from "./components/CardSortingSettings";
import {UserManagementControl} from "./components/UserManagementControl";
import {useParams} from "react-router-dom";
import {createProject, deleteProject, getProjects, switchToStartSorting} from "./http";
import {ProjectInfo, ProjectStatus} from "./types";
import {Button, Input} from "../../../system/UX";
import {ConfirmModal} from "../../../system/components";
import {useTranslate} from "./translates/useTranslate";
import {useDispatch} from "react-redux";
import {usersSlice} from "./reducers/usersReducer";
import {PreModerationModal} from "./components/PreModerationModal";


//TODO: Сортировать проекты (активные - выше)
//TODO: Сделать компонент скроллабл модальных окон
//TODO: Сделать нормальную синхронизацию с БД, когда в моменте мемняешь настройки
export const CardSortingAdmin = () => {
    const dispatch = useDispatch()
    const actions = usersSlice.actions
    const client_id = Number(useParams().client_id)
    const translate = useTranslate()
    const [projectName, setProjectName] = useState('')
    const [projectList, setProjectList] = useState<ProjectInfo[]>([])
    const [projectToSetUp, setProjectToSetUp] = useState(0)
    const [projectToDelete, setProjectToDelete] = useState(0)
    const [projectToAnswerModerate, setProjectToAnswerModerate] = useState(0)
    const [projectToStartSort, setProjectToStartSort] = useState(0)

    const createProjectHandler = () => {
        createProject(projectName, client_id).then(() => {
            getProjects(client_id).then((data) => {
                setProjectList(data)
                setProjectName('')
                dispatch(actions.setProjects(data.map(project => ({ id: project.id, name: project.name }))))
            })
        })
    }

    const deleteProjectHandler = () => {
        deleteProject(projectToDelete).then(() => {
            getProjects(client_id).then((data) => {
                setProjectList(data)
                dispatch(actions.setProjects(data.map(project => ({ id: project.id, name: project.name }))))
                dispatch(actions.deleteProject(projectToDelete))
            })
        })
        setProjectToDelete(0)
    }

    const startSortingHandler = () => {
        switchToStartSorting(projectToStartSort).then(() => {
            getProjects(client_id).then(setProjectList)
        })
        setProjectToStartSort(0)
    }

    const closeSettings = () => {
        setProjectToSetUp(0)
    }

    useEffect(() => {
        getProjects(client_id).then((data) => {
            setProjectList(data)
            dispatch(actions.setProjects(data.map(project => ({ id: project.id, name: project.name }))))
        })
    }, [])

    return (
        <div className={styles.cardSortingAdminContainer}>
            <div className={styles.settingsContainer}>
                <UserManagementControl />
                <div className={styles.commonSortingSettingsContainer}>
                    <h3 className={styles.sortSettingsHeader}>{translate.sortingSettings.projectsHeader}</h3>
                    <div className={styles.addUserOrProjectControl}>
                        <Input
                            placeholder={translate.sortingSettings.inputProjectPlaceholder}
                            label={translate.sortingSettings.inputProjectLabel}
                            containerClassName={styles.userOrProjectInputContainer}
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                        />
                        <Button
                            onClick={createProjectHandler}
                            className={styles.addUserOrProjectButtonStyles}
                        >
                            {translate.sortingSettings.addButton}
                        </Button>
                    </div>
                    <p>{translate.sortingSettings.pressNameToSetUp}</p>
                    <ul className={styles.projectList}>
                        {projectList.sort((p1, p2) => p2.id - p1.id).map(project => <li
                            key={project.id}
                            className={styles.project}
                        >
                            <div className={styles.projectNameStatus}>
                                <span
                                    onClick={() => setProjectToSetUp(project.id)}
                                    className={styles.projectName}
                                >
                                    {project.name}
                                </span>
                                <span>
                                    {translate.sortingSettings.status}{project.status}
                                </span>
                            </div>
                            <div className={styles.projectButtons}>
                                <Button
                                    onClick={() => setProjectToDelete(project.id)}
                                    variant="outlined"
                                    className={styles.projectButton}
                                >
                                    {translate.sortingSettings.deleteProjectButton}
                                </Button>
                                {project.status === ProjectStatus.SORTING_NOT_STARTED && <>
                                    <Button
                                        onClick={() => setProjectToAnswerModerate(project.id)}
                                        variant="outlined"
                                        className={styles.projectButton}
                                    >
                                        {translate.sortingSettings.preModerationButton}
                                    </Button>
                                    <Button
                                        onClick={() => setProjectToStartSort(project.id)}
                                        variant="outlined"
                                        className={styles.projectButton}
                                    >
                                        {translate.sortingSettings.startSortingButton}
                                    </Button>
                                </>}
                            </div>
                            <hr/>
                        </li>)}
                    </ul>

                    {projectToSetUp !== 0 && <CardSortingSettings
                        onClose={closeSettings}
                        projectId={projectToSetUp}
                        setProjectList={setProjectList}
                    />}

                    {projectToStartSort !== 0 && <ConfirmModal
                        confirmText={translate.confirmButton}
                        cancelText={translate.cancelButton}
                        onConfirm={startSortingHandler}
                        onCancel={() => setProjectToStartSort(0)}
                    >
                        {translate.sortingSettings.confirmStartSorting}
                    </ConfirmModal>}
                    {projectToDelete !== 0 && <ConfirmModal
                        confirmText={translate.confirmButton}
                        cancelText={translate.cancelButton}
                        onConfirm={deleteProjectHandler}
                        onCancel={() => setProjectToDelete(0)}
                    >
                        {translate.sortingSettings.confirmDeleteProject}
                    </ConfirmModal>}
                    {projectToAnswerModerate !== 0 && <PreModerationModal
                        projectId={projectToAnswerModerate}
                        onClose={() => setProjectToAnswerModerate(0)}
                    />}
                </div>
            </div>
        </div>
    );
};