import React, {FC, MouseEvent, useEffect, useState} from 'react';
import {Button, IconTextButton, Input, MinusIcon, PlusIcon} from '../../../../system/UX';
// @ts-ignore
import styles from '../CardSortingStyles.module.css'
import {useTranslate} from "../translates/useTranslate";
import {cardSortingSlice} from "../reducers/cardSortingReducer/cardSortingReducer";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {saveCards} from "../http";
import {HintButton, InstructionBlock, MouseHint} from "../../../../system/components";
import {getObjectFromLocalStorage, saveObjectInLocalStorage} from "../../../../../common/utils";
import {useParams} from "react-router-dom";

interface AddCardsControlProps {
    maxCards: number,
    minCards: number
}

const isAllCardsFilled = (cards: string[]) => {
    let isAllCardsFilledFlag = true
    cards.forEach(card => {
        if (card === '') isAllCardsFilledFlag = false
    })
    return isAllCardsFilledFlag
}

export const AddCardsControl: FC<AddCardsControlProps> = (props) => {
    const { maxCards, minCards } = props

    const translates = useTranslate()
    const dispatch = useAppDispatch()
    const { setCardsFilled } = cardSortingSlice.actions
    const [cards, setCards] = useState(new Array(minCards).fill(''))
    const question = useAppSelector(state => state.cardSortingReducer.info.question_list)
    const [isPending, setIsPending] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [isInstructionConfirmed, setIsInstructionConfirmed] = useState(true)
    const info = useAppSelector(state => state.cardSortingReducer.info)
    const project_id = Number(useParams().project_id)
    const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()

    const changeCardTextByIndex = (e: any, index: number) => {
        const modifiedCards = [...cards]
        modifiedCards[index] = e.target.value

        setIsDisabled(!isAllCardsFilled(modifiedCards))
        setCards(modifiedCards)
    }

    const addCard = () => {
        if (cards.length < maxCards) {
            setIsDisabled(true)
            const modifiedCards = [...cards]
            modifiedCards.push('')

            const projectDataObject = getObjectFromLocalStorage(String(project_id))
            saveObjectInLocalStorage(String(project_id), {
                ...projectDataObject,
                cards: modifiedCards
            })

            setCards(modifiedCards)
        }
    }

    const removeCard = () => {
        if (cards.length > minCards) {
            const modifiedCards = [...cards]
            modifiedCards.pop()

            setIsDisabled(!isAllCardsFilled(modifiedCards))

            const projectDataObject = getObjectFromLocalStorage(String(project_id))
            saveObjectInLocalStorage(String(project_id), {
                ...projectDataObject,
                cards: modifiedCards
            })

            setCards(modifiedCards)
        }
    }

    const save = () => {
        if (!isDisabled) {
            setIsPending(true)
            saveCards({cards: [{
                question_id: question ? question.questions[0].id : 0,
                cards: cards
            }]}).then(() => {
                dispatch(setCardsFilled())
            })
        }
    }

    const showButtonHint = (e: MouseEvent<HTMLDivElement>) => {
        if (isDisabled) setHintEvent(e)
    }

    useEffect(() => {
        const projectDataObject = getObjectFromLocalStorage(String(project_id))

        if (projectDataObject.cards) {
            setIsDisabled(!isAllCardsFilled(projectDataObject.cards))
            setCards(projectDataObject.cards)
        }

        if (projectDataObject.isCardsInstructionConfirmed) setIsInstructionConfirmed(true)
        else setIsInstructionConfirmed(false)
    }, [])

    return (
        <>
            {!isInstructionConfirmed && <InstructionBlock
                title={info.instruction_title}
                content={info.instruction_text}
                confirm={{
                    handler: () => {
                        setIsInstructionConfirmed(true)
                        const projectDataObject = getObjectFromLocalStorage(String(project_id))
                        saveObjectInLocalStorage(String(project_id), {
                            ...projectDataObject,
                            isCardsInstructionConfirmed: true
                        })
                    },
                    confirmationText: translates.gotItButton
                }}
            />}

            {isInstructionConfirmed && <div className={styles.questionPlayerContainer}>
                {hintEvent && <MouseHint event={hintEvent}>
                    {translates.sendCardsButtonHint}
                </MouseHint>}

                <p>{question && question.questions[0].text}</p>
                <div className={styles.questionInputContainer}>
                    {cards.map((card, index) => <Input
                        key={index}
                        placeholder={translates.cardInputPlaceholder}
                        isTextareaView={true}
                        inputClassName={styles.questionInput}
                        value={card}
                        onChange={(e) => changeCardTextByIndex(e, index)}
                        onBlur={() => {
                            const projectDataObject = getObjectFromLocalStorage(String(project_id))
                            saveObjectInLocalStorage(String(project_id), {
                                ...projectDataObject,
                                cards
                            })
                        }}
                    />)}
                </div>
                {(question && question.min_cards_per_question !== question.max_cards_per_question) && <div className={styles.plusAndMinusControls}>
                    <IconTextButton label={translates.removeCardButton} onClick={removeCard}>
                        <MinusIcon />
                    </IconTextButton>
                    <IconTextButton label={translates.addCardButton} onClick={addCard}>
                        <PlusIcon />
                    </IconTextButton>
                </div>}
                <HintButton onClick={() => setIsInstructionConfirmed(false)}>
                    {translates.hintNotification}
                </HintButton>
                <Button
                    onClick={save}
                    isPending={isPending}
                    disabled={isDisabled}
                    onMouseMove={showButtonHint}
                    onMouseLeave={() => setHintEvent(null)}
                >
                    {translates.save + ` (${cards.length} / ${maxCards})`}
                </Button>
            </div>}
        </>
    );
};