import React from 'react';
import {Auth} from "../../project/modules";
// @ts-ignore
import styles from '../CenterLayout.module.css'

export const AuthPage = () => {
    return (
        <div className={styles.centerLayout}>
            <Auth />
        </div>
    );
};