import React, {FC, MouseEvent, useRef, useState} from 'react';
import {useTranslate} from "../../translates/useTranslate";
// @ts-ignore
import styles from "../../CardSortingStyles.module.css";
import {MouseHint} from "../../../../../system/components";
import classNames from "classnames";
import {ArrowBackIcon, LikeIcon} from "../icons";
import {CardProps} from "./CardInBank";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {cardSortingSlice} from "../../reducers/cardSortingReducer/cardSortingReducer";

interface CardInGroupProps extends CardProps {
    groupId: number
}

//TODO: тут тоже вынести rem в хендлере подсказки текста
export const CardInGroup: FC<CardInGroupProps> = (props) => {
    const { id, text, grade_status } = props.card

    const dispatch = useAppDispatch()
    const {
        moveFromGroupToBank,
        togglePickedCard
    } = cardSortingSlice.actions
    const setGrade = cardSortingSlice.actions.setGrade
    const translates = useTranslate()
    const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()
    const [currentHint, setCurrentHint] = useState('')
    const { like_hint, dislike_hint, neutral_grade_hint } = useAppSelector(state => state.cardSortingReducer.info)
    const pickedCards = useAppSelector(state => state.cardSortingReducer.group_state.pickedCards)
    const textRef = useRef<HTMLParagraphElement>(null)

    const textHintHandler = (e: MouseEvent<HTMLParagraphElement>) => {
        if (textRef.current && textRef.current.offsetHeight > 25) {
            setHintEvent(e)
            setCurrentHint(text)
        }
    }

    return (
        <div
            className={classNames(styles.card, styles.cardInGroup)}
            style={{ backgroundColor: pickedCards.cards.includes(id) ? 'silver' : 'white' }}
        >
            {hintEvent && <MouseHint event={hintEvent}>
                {currentHint}
            </MouseHint>}
            <div
                onMouseEnter={() => setCurrentHint(translates.backMouseHint)}
                onMouseMove={(e) => setHintEvent(e)}
                onMouseLeave={() => setHintEvent(null)}
                className={styles.cursorPointer}
                onClick={() => dispatch(moveFromGroupToBank(id))}
            >
                <ArrowBackIcon className={styles.arrowBack}/>
            </div>
            <p
                onMouseMove={textHintHandler}
                onMouseLeave={() => setHintEvent(null)}
                className={classNames(styles.cardText, styles.cardInGroupText)}
                onClick={() => dispatch(togglePickedCard({ group: props.groupId, cardId: id }))}
            >
                <span ref={textRef}>{text}</span>
            </p>
            <div className={styles.likeControls}>
                <div
                    onMouseEnter={() => setCurrentHint(like_hint)}
                    onMouseMove={(e) => setHintEvent(e)}
                    onMouseLeave={() => setHintEvent(null)}
                    className={styles.cursorPointer}
                    onClick={() => dispatch(setGrade({id, grade: 'liked'}))}
                >
                    <LikeIcon className={classNames(styles.likeIcon, grade_status === 'liked' && styles.activeLikeIcon)}/>
                </div>
                <div
                    onMouseEnter={() => setCurrentHint(neutral_grade_hint)}
                    onMouseMove={(e) => setHintEvent(e)}
                    onMouseLeave={() => setHintEvent(null)}
                    className={classNames(styles.cursorPointer, styles.neutralIconContainer)}
                    onClick={() => dispatch(setGrade({id, grade: 'neutral'}))}
                >
                    <div className={classNames(styles.neutralIcon, grade_status === 'neutral' && styles.activeNeutralIcon)}/>
                </div>
                <div
                    onMouseEnter={() => setCurrentHint(dislike_hint)}
                    onMouseMove={(e) => setHintEvent(e)}
                    onMouseLeave={() => setHintEvent(null)}
                    className={styles.cursorPointer}
                    onClick={() => dispatch(setGrade({id, grade: 'disliked'}))}
                >
                    <LikeIcon className={classNames(styles.likeIcon, grade_status === 'disliked' && styles.activeLikeIcon)} isDislike={true}/>
                </div>
            </div>
        </div>
    )
};