import React, {FC} from 'react';
// @ts-ignore
import styles from './PreloaderStyles.module.css';
import classNames from "classnames";

interface PreloaderProps {
    isFullPage?: boolean
}

export const Preloader: FC<PreloaderProps> = (props) => {
    const { isFullPage = false } = props

    return (
        <div className={classNames(styles.circlePreloader, isFullPage && styles.fullPageCircle)}>
            <div className={styles.circle} />
        </div>
    );
};

