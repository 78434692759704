import React, {FC, ReactNode} from 'react';
// @ts-ignore
import styles from './IconTextButtonStyles.module.css'

interface IconButtonProps {
    onClick?: () => void,
    label: string
    children: ReactNode
    color?: string
}

export const IconTextButton: FC<IconButtonProps> = (props) => {
    const { onClick, children, label, color = '#323232' } = props

    return (
        <button className={styles.addButton} onClick={onClick} style={{ borderColor: color }}>
            {children}
            {label && <span style={{ color }}>{label}</span>}
        </button>
    );
};