import {AuthPage, ErrorPage} from "../pages";
import {Pages, Route} from "../common/types";

export const publicRoutes: Route[] = [{
    path: `${Pages.auth}/:token/:project_id`,
    element: AuthPage
}, {
    path: `${Pages.auth}/:token`,
    element: AuthPage
}, {
    path: `${Pages.error}/:error`,
    element: ErrorPage
}]