import React, {FC, useEffect, useState} from 'react';
import {Button, Modal, Portal} from "../../../../../system/UX";
import {useTranslate} from "../../translates/useTranslate";
// @ts-ignore
import styles from "../../CardSortingStyles.module.css";
import {useAppSelector} from "../../../../../../store/store";
import {Card} from "../../reducers/cardSortingReducer/cardCortingReducerTypes";
import SimpleBar from "simplebar-react";
import CardInLikeModal from "./CardInLikeModal";
import {useToggle} from "../../../../../../common/hooks";

interface LikesModalProps {
    onConfirm: (isPending: { state: boolean; toggleValue: () => void;}) => void
    onCLose: () => void
}

export const LikesModal:FC<LikesModalProps> = (props) => {
    const { onCLose, onConfirm } = props

    const translates = useTranslate()
    const groups = useAppSelector(state => state.cardSortingReducer.group_state.groups)
    const isPending = useToggle(false)

    const [ungradedCards, setUngradedCards] = useState<Card[]>([])
    const [isAllCardsGraded, setIsAllCardsGraded] = useState(false)

    const findUngradedCards = () => {
        const cards: Card[] = []

        groups.forEach(group => {
            group.cards.forEach(card => {
                if (card.grade_status === 'unset') {
                    cards.push(card)
                }
            })
        })

        setIsAllCardsGraded(cards.length === 0)
        return cards
    }

    useEffect(() => {
        findUngradedCards()
    }, [groups])

    useEffect(() => {
        setUngradedCards(findUngradedCards)
    }, [])

    return (
        <Portal>
            <Modal>
                <h3>{translates.setLikesRequest}</h3>
                <SimpleBar autoHide={false} className={styles.lakeModalScrollContainer}>
                    <div className={styles.likeModalCardsContainer}>
                        {ungradedCards.map(card => <CardInLikeModal card={card} />)}
                    </div>
                </SimpleBar>
                <div className={styles.likesModalButtonContainer}>
                    <Button onClick={onCLose}>Отмена</Button>
                    <Button
                        onClick={() => onConfirm(isPending)}
                        disabled={!isAllCardsGraded}
                        isPending={isPending.state}
                    >
                        Сохранить
                    </Button>
                </div>
            </Modal>
        </Portal>
    );
};