export interface ProjectSettingsIn {
    question: string
    max_cards_per_question: string
    min_cards_per_question: string
    instruction_text: string
    instruction_title: string
    sorting_instruction_text: string
    mobile_sorting_instruction_text: string
    sorting_instruction_title: string
    like_hint: string
    dislike_hint: string
    neutral_grade_hint: string
    like_limiter: string
    dislike_limiter: string
}


export interface ProjectSettingOut extends ProjectSettingsIn {
    id: number
}

// TODO: Подумать над правильной декомпозицией интерфейса статусов (и тут, и в модуле кард сортинка, согласовать с бэком)
export type AdminUserSortingStatus =
    'project not opened' |
    'collecting cards' |
    'waiting for sorting' |
    'sorting not started' |
    'doing sort' |
    'sorting finished' |
    'project settings unset'
export const arrayOfAdminUserSortingStatus = [
    'admins',
    'project not opened',
    'collecting cards',
    'waiting for sorting',
    'sorting not started',
    'doing sort',
    'sorting finished',
    'project settings unset'
]

export interface UserRowIn {
    id: number
    email: string
    name?: string
    is_admin: boolean
    registration_token: string
    projects: UserProject[]
}

export interface BaseProject {
    id: number
    name: string
}

export interface UserProject extends BaseProject {
    status: AdminUserSortingStatus
}

export enum ProjectStatus {
    SORTING_STARTED = 'sorting started',
    SORTING_NOT_STARTED = 'sorting not started',
    UNSET = 'settings unset'
}

export interface ProjectInfo extends BaseProject {
    status: ProjectStatus
}

export interface BaseUserSettings {
    user_id: number
    is_admin: boolean
    email: string
    name: string
}

export interface UserSettings extends BaseUserSettings {
    projects: UserProject[]
}

export interface UserSettingsDTO extends BaseUserSettings {
    project_ids: number[]
}

export interface CardForEdit {
    id: number
    text: string
    author_id: number
    question_id: number
}