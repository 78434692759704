import React, {useEffect} from 'react'
import {Route, Routes, useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from "../store/store";
import {publicRoutes} from "./public";
import {privateRoutes} from "./private";
import {ErrorMessages, Pages} from "../common/types";
import {isCurrentPageAuth, isTokenInStorage, readJwtPayload} from "../common/utils";
import {appSlice} from "../store/appReducer/appReducer";
import {getLanguage} from "../store/appReducer/ActionCreators";
import {UserRole} from "../store/appReducer/appReducerTypes";
import {adminRoutes} from "./admin";
import {superadminRoutes} from "./superadmin";

function AppRouter() {
	const user = useAppSelector(state => state.appReducer.user)
	const dispatch = useAppDispatch()
	const setUser = appSlice.actions.setUser
	const navigate = useNavigate()

	useEffect(() => {
		if (isTokenInStorage()) {
			const payload = readJwtPayload(String(localStorage.getItem('token')))
			dispatch(setUser({
				name: payload.name,
				email: payload.sub,
				user_role: payload.user_role,
				client_id: payload.client_id
			}))
		} else if (!isCurrentPageAuth()) {
			navigate(`${Pages.error}/${ErrorMessages.NO_ACCESS}`)
		}
	}, [])


	return (
		<Routes>
			{publicRoutes.map(route => <Route key={route.path} path={route.path} element={<route.element />} />)}
			{user && privateRoutes.map(route => <Route key={route.path} path={route.path} element={<route.element />} />)}

			{(user?.user_role !== UserRole.COMMON_USER) && adminRoutes.map(route => (
				<Route key={route.path} path={route.path} element={<route.element />} />
			))}

			{(user?.user_role === UserRole.SUPERADMIN) && superadminRoutes.map(route => (
				<Route key={route.path} path={route.path} element={<route.element />} />
			))}
		</Routes>
	)
}

export default AppRouter
