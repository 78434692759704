import {Translates} from "./translates";

export const enTranslates: Translates = {
    gotItButton: 'Got it!',
    confirmButton: 'Yes',
    cancelButton: 'No',
    userManager: {
        userSettingsTitle: 'User settings',
        confirmSaveUserSettings: 'Are you sure you want to save the selected projects and data for the user?',
        email: 'Login',
        name: 'Name',
        isAdmin: 'Is user an admin',
        userProjects: 'Projects in which user participates:',
        admin: 'admin',
        admins: 'Administrators',
        userManagementTitle: 'User management',
        createUserError: 'Current user already created',
        confirmDeleteUserMessage: 'Are you sure you want to delete this user?',
        deleteUserHint: 'Delete user',
        notStartedStatus: 'not started',
        openSettingsHint: 'Open user settings',
        addUserLabel: 'Enter the new user\'s login',
        addUserPlaceholder: 'Add a new user',
        addUserButton: 'Add user',
        notAuthStatus: 'Not authorized',
        downloadXLSX: 'Download results in xlsx',
        searchByNameOrLogin: 'Search by name or login',
        statusFilter: 'Status filters:',
        projectFilter: 'Project filters:',
        clickToCopyLinkHint: 'Click to copy the authorization link for this project to the clipboard',
        linkCopied: 'Link copied',
    },
    sortingSettings: {
        errors: {
            emptyQuestion: 'You have not entered a question',
            emptyMinCardsPerQuestion: 'You have not entered the minimum number of cards',
            emptyMaxCardsPerQuestion: 'You have not entered the maximum number of cards',
            emptyLikeHint: 'You have not entered a hint for the "like" button',
            emptyDislikeHint: 'You have not entered a hint for the "dislike" button',
            emptyNeutralGradeHint: 'You have not entered a hint for the "neutral grade" button',
            emptyLikeAllHint: 'You have not entered a hint for the "like all" button',
            emptyDislikeAllHint: 'You have not entered a hint for the "dislike all" button',
            emptyInstructionTitle: 'You have not entered an instruction title',
            emptyInstructionText: 'You have not entered the instruction text',
            emptySortingInstructionTitle: 'You have not entered an sorting instruction title',
            emptySortingInstructionText: 'You have not entered the sorting instruction text',
        },
        cardTrashIconHint: 'Delete card',
        copyCardIconHint: 'Copy card',
        cardEditorConfirmDelete: 'Are you sure you want to delete this card?',
        cardEditorHeader: 'Pre-moderation of answers',
        cardEditorPlaceholder: 'No cards created',
        pressNameToSetUp: 'Click on the project name to set up',
        projectsHeader: 'Projects',
        passSort: 'Pass the survey by yourself',
        confirmStartSorting: 'Are you sure you want to proceed to the sorting step? After switching the stage, it will not be possible to go back to the stage of collecting cards.',
        confirmDeleteProject: 'Are you sure you want to delete this project?',
        inputProjectLabel: 'Enter the new project\'s name',
        inputProjectPlaceholder: 'Enter name here...',
        addButton: 'Add',
        deleteProjectButton: 'Delete project',
        startSortingButton: 'Start sorting stage',
        preModerationButton: 'Pre-moderation of answers',
        status: 'Status: ',
        settingsTitle: 'Sorting settings',
        settingsSaved: 'Sorting settings saved',
        inputQuestionLabel: 'Question text',
        inputQuestionPlaceholder: 'Enter question text...',
        inputMinCardsLabel: 'Minimum number of cards',
        inputMaxCardsLabel: 'Maximum number of cards',
        inputLikeHintLabel: 'Like button hint',
        inputLikeHintPlaceholder: 'Enter like button hint...',
        inputDislikeHintLabel: 'Dislike button hint',
        inputDislikeHintPlaceholder: 'Enter dislike button hint...',
        inputLikeLimiterHintLabel: 'Limit on the number of likes in percentage (leave 0 if you don’t want to set a limit)',
        inputLikeLimiterPlaceholder: 'Enter limit on the number of likes...',
        inputNeutralGradeHintLabel: 'Neutral grade button hint',
        inputNeutralGradeHintPlaceholder: 'Enter neutral grade button hint...',
        inputDislikeLimiterHintLabel: 'Limit on the number of dislikes in percentage (leave 0 if you don’t want to set a limit)',
        inputDislikeLimiterPlaceholder: 'Enter limit on the number of dislikes...',
        inputInstructionTitlePlaceholder: 'Enter instruction title...',
        inputInstructionTitleLabel: 'Instruction title',
        inputInstructionTextLabel: 'Instruction text',
        inputInstructionTextPlaceholder: 'Enter Instruction text (use of <br> tag is allowed)',
        inputSortingInstructionTitleLabel: 'Sorting instruction title',
        inputSortingInstructionTitlePlaceholder: 'Enter sorting instruction title...',
        inputSortingInstructionTextLabel: 'Sorting instruction text',
        inputSortingInstructionTextPlaceholder: 'Enter sorting instruction text (use of <br> tag is allowed)',
        inputMobileSortingInstructionTextLabel: 'Mobile sorting instruction text',
        inputMobileSortingInstructionTextPlaceholder: 'Enter mobile sorting instruction text (use of <br> tag is allowed)',
        saveButton: 'Save'
    }
}