import React, {Dispatch, FC, FormEventHandler, SetStateAction, useEffect, useState} from 'react';
import {Button, Input, LargeModal, Portal} from "../../../../system/UX";
// @ts-ignore
import styles from '../CardSortingAdminStyles.module.css'
import {getProjectSettings, saveProjectSettings} from "../http";
import {ProjectInfo, ProjectSettingsIn, ProjectStatus} from "../types";
import {AlertModal} from "../../../../system/components";
import {useToggle} from "../../../../../common/hooks";
import {Hyperlink} from "../../../../system/UX/Link";
import {Pages} from "../../../../../common/types";
import {useTranslate} from "../translates/useTranslate";
import {CrossIcon} from "../../CardSorting/components/icons";

interface CardSortingSettingsProps {
    projectId: number
    onClose: () => void
    setProjectList: Dispatch<SetStateAction<ProjectInfo[]>>
}

export const CardSortingSettings:FC<CardSortingSettingsProps> = (props) => {
    const translates = useTranslate()
    const { projectId, onClose } = props
    const [isSortingAllowed, setIsSortingAllowed] = useState(false)
    const isPending = useToggle(false)
    const [alertMessage, setAlertMessage] = useState('')

    const errorHandler = (projectSettings: ProjectSettingsIn) => {
        if (!projectSettings.question) return translates.sortingSettings.errors.emptyQuestion
        if (!projectSettings.min_cards_per_question) return translates.sortingSettings.errors.emptyMinCardsPerQuestion
        if (!projectSettings.max_cards_per_question) return translates.sortingSettings.errors.emptyMaxCardsPerQuestion
        if (!projectSettings.instruction_title) return translates.sortingSettings.errors.emptyInstructionTitle
        if (!projectSettings.instruction_text) return translates.sortingSettings.errors.emptyInstructionText
        if (!projectSettings.sorting_instruction_title) return translates.sortingSettings.errors.emptySortingInstructionTitle
        if (!projectSettings.sorting_instruction_text) return translates.sortingSettings.errors.emptySortingInstructionText
        if (!projectSettings.like_hint) return translates.sortingSettings.errors.emptyLikeHint
        if (!projectSettings.neutral_grade_hint) return translates.sortingSettings.errors.emptyNeutralGradeHint
        if (!projectSettings.dislike_hint) return translates.sortingSettings.errors.emptyDislikeHint
        return ''
    }

    const [projectSettings, setProjectSettings] = useState<ProjectSettingsIn>({
        question: '',
        max_cards_per_question: '',
        min_cards_per_question: '',
        instruction_text: '',
        instruction_title: '',
        sorting_instruction_title: '',
        mobile_sorting_instruction_text: '',
        sorting_instruction_text: '',
        like_hint: '',
        dislike_hint: '',
        neutral_grade_hint: '',
        like_limiter: '0',
        dislike_limiter: '0',
    })

    useEffect(() => {
        getProjectSettings(projectId).then(data => {
            if (data) {
                setProjectSettings(data)
                setIsSortingAllowed(true)
            }
        })
    }, [])

    const submit: FormEventHandler = (e) => {
        e.preventDefault()
        const error = errorHandler(projectSettings)
        if (error) return setAlertMessage(error)


        isPending.toggleValue()
        saveProjectSettings({...projectSettings, id: Number(projectId)}).then(() => {
            isPending.toggleValue()
            setIsSortingAllowed(true)
            setAlertMessage(translates.sortingSettings.settingsSaved)
            props.setProjectList(projects => projects.map(project => {
                if (project.id === props.projectId) {
                    return {
                        ...project,
                        status: ProjectStatus.SORTING_NOT_STARTED
                    }
                }

                return project
            }))
        })
    }

    return (
        <Portal>
            <LargeModal>
                <form className={styles.sortingSettingsContainer} onSubmit={submit}>
                    {alertMessage && <AlertModal
                        submitText={translates.gotItButton}
                        onSubmit={() => {
                            if (alertMessage === translates.sortingSettings.settingsSaved) {
                                onClose()
                            }

                            setAlertMessage('')
                        }}
                    >
                        {alertMessage}
                    </AlertModal>}
                    <h3 className={styles.sortSettingsHeader}>{translates.sortingSettings.settingsTitle}</h3>
                    <div className={styles.closeSettings} onClick={onClose}>
                        <CrossIcon />
                    </div>
                    {isSortingAllowed && <Hyperlink href={Pages.main} className={styles.goSortingLink}>
                        {translates.sortingSettings.passSort}
                    </Hyperlink>}
                    <Input
                        label={translates.sortingSettings.inputQuestionLabel}
                        placeholder={translates.sortingSettings.inputQuestionPlaceholder}
                        value={projectSettings.question}
                        onChange={(e) => setProjectSettings({...projectSettings, question: e.target.value})}
                        containerClassName={styles.settingsInputContainer}
                        inputClassName={styles.questionAreaInput}
                        isTextareaView={true}
                    />
                    <Input
                        label={translates.sortingSettings.inputMinCardsLabel}
                        type="number"
                        placeholder="3"
                        value={projectSettings.min_cards_per_question}
                        onChange={(e) => e.target.value.length < 3 && setProjectSettings({
                            ...projectSettings,
                            min_cards_per_question: e.target.value
                        })}
                        inputClassName={styles.counterInput}
                    />
                    <Input
                        label={translates.sortingSettings.inputMaxCardsLabel}
                        type="number"
                        placeholder="7"
                        value={projectSettings.max_cards_per_question}
                        onChange={(e) => e.target.value.length < 3 && setProjectSettings({
                            ...projectSettings,
                            max_cards_per_question: e.target.value
                        })}
                        inputClassName={styles.counterInput}
                    />
                    <Input
                        label={translates.sortingSettings.inputLikeLimiterHintLabel}
                        type="number"
                        placeholder={translates.sortingSettings.inputLikeLimiterPlaceholder}
                        value={projectSettings.like_limiter}
                        onChange={(e) => setProjectSettings({...projectSettings, like_limiter: e.target.value})}
                        containerClassName={styles.settingsInputContainer}
                    />
                    <Input
                        label={translates.sortingSettings.inputDislikeLimiterHintLabel}
                        type="number"
                        placeholder={translates.sortingSettings.inputDislikeLimiterPlaceholder}
                        value={projectSettings.dislike_limiter}
                        onChange={(e) => setProjectSettings({...projectSettings, dislike_limiter: e.target.value})}
                        containerClassName={styles.settingsInputContainer}
                    />
                    <Input
                        label={translates.sortingSettings.inputLikeHintLabel}
                        placeholder={translates.sortingSettings.inputLikeHintPlaceholder}
                        value={projectSettings.like_hint}
                        onChange={(e) => setProjectSettings({...projectSettings, like_hint: e.target.value})}
                        containerClassName={styles.settingsInputContainer}
                    />
                    <Input
                        label={translates.sortingSettings.inputNeutralGradeHintLabel}
                        placeholder={translates.sortingSettings.inputNeutralGradeHintPlaceholder}
                        value={projectSettings.neutral_grade_hint}
                        onChange={(e) => setProjectSettings({...projectSettings, neutral_grade_hint: e.target.value})}
                        containerClassName={styles.settingsInputContainer}
                    />
                    <Input
                        label={translates.sortingSettings.inputDislikeHintLabel}
                        placeholder={translates.sortingSettings.inputDislikeHintPlaceholder}
                        value={projectSettings.dislike_hint}
                        onChange={(e) => setProjectSettings({...projectSettings, dislike_hint: e.target.value})}
                        containerClassName={styles.settingsInputContainer}
                    />
                    <Input
                        label={translates.sortingSettings.inputInstructionTitleLabel}
                        placeholder={translates.sortingSettings.inputInstructionTitlePlaceholder}
                        value={projectSettings.instruction_title}
                        onChange={(e) => setProjectSettings({...projectSettings, instruction_title: e.target.value})}
                        containerClassName={styles.settingsInputContainer}
                    />
                    <Input
                        label={translates.sortingSettings.inputInstructionTextLabel}
                        placeholder={translates.sortingSettings.inputInstructionTextPlaceholder}
                        value={projectSettings.instruction_text}
                        onChange={(e) => setProjectSettings({...projectSettings, instruction_text: e.target.value})}
                        containerClassName={styles.settingsInputContainer}
                        inputClassName={styles.textAreaInput}
                        isTextareaView={true}
                    />
                    <Input
                        label={translates.sortingSettings.inputSortingInstructionTitleLabel}
                        placeholder={translates.sortingSettings.inputSortingInstructionTitlePlaceholder}
                        value={projectSettings.sorting_instruction_title}
                        onChange={(e) => setProjectSettings({
                            ...projectSettings,
                            sorting_instruction_title: e.target.value
                        })}
                        containerClassName={styles.settingsInputContainer}
                    />
                    <Input
                        label={translates.sortingSettings.inputSortingInstructionTextLabel}
                        placeholder={translates.sortingSettings.inputSortingInstructionTextPlaceholder}
                        value={projectSettings.sorting_instruction_text}
                        onChange={(e) => setProjectSettings({
                            ...projectSettings,
                            sorting_instruction_text: e.target.value
                        })}
                        containerClassName={styles.settingsInputContainer}
                        inputClassName={styles.textAreaInput}
                        isTextareaView={true}
                    />
                    <Input
                        label={translates.sortingSettings.inputMobileSortingInstructionTextLabel}
                        placeholder={translates.sortingSettings.inputMobileSortingInstructionTextPlaceholder}
                        value={projectSettings.mobile_sorting_instruction_text}
                        onChange={(e) => setProjectSettings({
                            ...projectSettings,
                            mobile_sorting_instruction_text: e.target.value
                        })}
                        containerClassName={styles.settingsInputContainer}
                        inputClassName={styles.textAreaInput}
                        isTextareaView={true}
                    />
                    <Button type="submit" isPending={isPending.state}>{translates.sortingSettings.saveButton}</Button>
                </form>
            </LargeModal>
        </Portal>
    );
};