// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalStyles_modalBackground__\\+r0j1 {\n    z-index: 100;\n    position: fixed;\n    top: 0;\n    left: 0;\n    overflow: hidden;\n    width: 100vw;\n    height: 100vh;\n    background: rgba(0, 0, 0, 0.3);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.ModalStyles_modal__9SvWO {\n    background: white;\n    border-radius: 0.625rem;\n    padding: 2rem 3.125rem;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n}\n\n@media (max-width: 1024px) {\n    .ModalStyles_modal__9SvWO {\n        padding: 2rem 1.5rem;\n    }\n}", "",{"version":3,"sources":["webpack://./src/project/system/UX/Modal/ModalStyles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,MAAM;IACN,OAAO;IACP,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".modalBackground {\n    z-index: 100;\n    position: fixed;\n    top: 0;\n    left: 0;\n    overflow: hidden;\n    width: 100vw;\n    height: 100vh;\n    background: rgba(0, 0, 0, 0.3);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.modal {\n    background: white;\n    border-radius: 0.625rem;\n    padding: 2rem 3.125rem;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n}\n\n@media (max-width: 1024px) {\n    .modal {\n        padding: 2rem 1.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBackground": "ModalStyles_modalBackground__+r0j1",
	"modal": "ModalStyles_modal__9SvWO"
};
export default ___CSS_LOADER_EXPORT___;
