import React, { FC, MouseEventHandler } from 'react'
// @ts-ignore
import styles from './Styles.module.css'
import classNames from "classnames";

interface ButtonProps {
	onClick?: MouseEventHandler<HTMLButtonElement>
	onMouseMove?: MouseEventHandler<HTMLDivElement>
	onMouseLeave?: MouseEventHandler<HTMLDivElement>
	className?: string
	children: string
	isPending?: boolean
	type?: 'button' | 'submit'
	variant?: 'contained' | 'outlined'
	disabled?: boolean
}

export const Button: FC<ButtonProps> = (props) => {
	const {
		className,
		children,
		type = 'button',
		onClick = () => undefined,
		variant = 'contained',
		isPending = false,
		disabled = false,
		onMouseMove,
		onMouseLeave
	} = props

	return (
		<div
			className={styles.buttonContainer}
			onMouseMove={onMouseMove}
			onMouseLeave={onMouseLeave}
		>
			<button
				className={classNames(
					styles.contained,
					variant === 'outlined' && styles.outlined,
					disabled && styles.disabled,
					className
				)}
				type={type}
				onClick={onClick}
				disabled={disabled || isPending}
			>
				{children}
			</button>
			{isPending && (
				<div className={styles.loaderContainer}>
					<div className={styles.loader} />
				</div>
			)}
		</div>
	)
}
