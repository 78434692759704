import React, {useState} from 'react';
// @ts-ignore
import styles from "../CardSortingStyles.module.css";
import {LikeIcon, TrashIcon} from "./icons";
import {Bin} from "./DesktopSorting/Bin";
import {Button, IconButton, PlusIcon, ProgressBar} from "../../../../system/UX";
import {useAppDispatch, useAppSelector} from "../../../../../store/store";
import {cardSortingSlice} from "../reducers/cardSortingReducer/cardSortingReducer";
import {AlertModal, ConfirmModal, HintButton} from "../../../../system/components";
import {useToggle} from "../../../../../common/hooks";
import {useTranslate} from "../translates/useTranslate";
import {saveSorting} from "../http";
import {useNavigate, useParams} from "react-router-dom";
import {getErrorUrl} from "../../../../../common/utils";
import {LikesModal} from "./DesktopSorting/LikesModal";
import {countLikedAndDislikedCards} from "../utils";


//TODO: убрать из местного транслейта блок CardCounter
export const SortingControls = ({ onClick }: { onClick?: (e: any) => void}) => {
    const navigate = useNavigate()
    const confirmModal = useToggle(false)
    const [error, setError] = useState('')
    const binModal = useToggle(false)
    const translate = useTranslate()
    const project_id = Number(useParams().project_id)
    const dispatch = useAppDispatch()
    const { createGroup, finishSort, createGroupAndMovePickedCards } = cardSortingSlice.actions
    const { group_state, info } = useAppSelector(state => state.cardSortingReducer)
    const isPending = useToggle(false)
    const likeModal = useToggle(false)

    const { likeCounter, dislikeCounter } = countLikedAndDislikedCards(group_state.groups)

    const checkSortingErrors = () => {
        if (group_state.bank.length !== 0) {
            return setError(translate.sortingErrors.notAllCardsSorted)
        }

        if (info.like_limit !== 0 && info.like_limit !== likeCounter) {
            return setError(translate.sortingErrors.notAllLikesGiven)
        }

        if (info.dislike_limit !== 0 && info.dislike_limit !== dislikeCounter) {
            return setError(translate.sortingErrors.notAllDislikesGiven)
        }

        for (let i = 0; i < group_state.groups.length; i++) {
            if (group_state.groups[i].name === '') return setError(translate.sortingErrors.emptyGroupName)
            if (group_state.groups[i].name === 'bin') return setError(translate.sortingErrors.binNameUsed)
        }

        if (info.like_limit === 0 && info.dislike_limit === 0) {
            let isAllCardsRated = true
            group_state.groups.forEach(group => {
                group.cards.forEach(card => {
                    if (card.grade_status === 'unset') isAllCardsRated = false
                })
            })
            if (!isAllCardsRated) return likeModal.toggleValue()
        }

        confirmModal.toggleValue()
    }

    const submitSorting = (isPending: { state: boolean; toggleValue: () => void; }) => {
        isPending.toggleValue()
        saveSorting(group_state, project_id, true).then(() => {
            isPending.toggleValue()
            confirmModal.toggleValue()
            dispatch(finishSort())
        }).catch(e => {
            navigate(getErrorUrl(e.message))
        })
    }

    const addGroupButtonHandler = () => {
        if (group_state.pickedCards.cards.length === 0) {
            dispatch(createGroup(translate.group))
        } else {
            dispatch(createGroupAndMovePickedCards(translate.group))
        }
    }

    return (
        <div className={styles.controls}>
            {error && <AlertModal
                onSubmit={() => setError('')}
                submitText={translate.gotItButton}
            >
                {error}
            </AlertModal>}

            {likeModal.state && <LikesModal onConfirm={submitSorting} onCLose={likeModal.toggleValue}/>}

            {confirmModal.state && <ConfirmModal
                onConfirm={() => submitSorting(isPending)}
                onCancel={confirmModal.toggleValue}
                isPending={isPending.state}
                confirmText={translate.yes}
                cancelText={translate.no}
            >
                {translate.saveSortingConfirm}
            </ConfirmModal>}

            <div className={styles.addGroup}>
                <HintButton onClick={onClick}>
                    {translate.hintNotification}
                </HintButton>
                <IconButton onClick={binModal.toggleValue} isActive={binModal.state}>
                    <TrashIcon />
                </IconButton>
                {binModal.state && <Bin closeHandler={binModal.toggleValue}/>}
                <button className={styles.addGroupControl} onClick={addGroupButtonHandler}>
                    <PlusIcon stroke="black"/>
                    <p>{translate.addGroupButton}</p>
                </button>
            </div>

            {info.like_limit !== 0 && <div className={styles.progressBarContainer}>
                <LikeIcon className={styles.progressBarLikeIcon}/>
                <ProgressBar
                    max={info.like_limit}
                    current={likeCounter}
                    height="1rem"
                    width="4rem"
                    color='#02D589'
                    isQuantityShown={true}
                />
            </div>}

            {info.dislike_limit !== 0 && <div className={styles.progressBarContainer}>
                <LikeIcon className={styles.progressBarLikeIcon} isDislike={true}/>
                <ProgressBar
                    max={info.dislike_limit}
                    current={dislikeCounter}
                    height="1rem"
                    width="4rem"
                    color='#02D589'
                    isQuantityShown={true}
                />
            </div>}

            <div className={styles.progressBarContainer}>
                <span className={styles.leftToSort}>left to sort:</span>
                <ProgressBar
                    max={group_state.total_number_of_cards}
                    current={group_state.total_number_of_cards - group_state.bank.length}
                    height="1rem"
                    width="4rem"
                    color='#02D589'
                    isQuantityShown={true}
                />
            </div>

            <Button variant="outlined" onClick={checkSortingErrors}>{translate.finishSortButton}</Button>
        </div>
    );
};

/*
{info.like_limit !== 0 && <p className={styles.sortedCounter}>
    {likeCounter}
    {translate.cardCounter.of}
    {info.like_limit}
    {translate.cardCounter.liked}
</p>}

<p className={styles.sortedCounter}>
    {translate.cardCounter.sorted}
    {group_state.total_number_of_cards - group_state.bank.length}
    {translate.cardCounter.of}
    {group_state.total_number_of_cards}
    {translate.cardCounter.cards}
</p>

{info.dislike_limit !== 0 && <p className={styles.sortedCounter}>
    {dislikeCounter}
    {translate.cardCounter.of}
    {info.dislike_limit}
    {translate.cardCounter.disliked}
</p>}*/
