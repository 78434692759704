import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import styles from '../CardSortingStyles.module.css'
import {BankOfCards} from "./DesktopSorting/BankOfCards";
import {Groups} from "./DesktopSorting/Groups";
import {SortingControls} from "./SortingControls";
import {InstructionBlock} from "../../../../system/components";
import {useTranslate} from "../translates/useTranslate";
import {useAppSelector} from "../../../../../store/store";
import {getObjectFromLocalStorage, saveObjectInLocalStorage} from "../../../../../common/utils";
import {saveSorting} from "../http";
import {useParams} from "react-router-dom";
import {DesktopSorting} from "./DesktopSorting/DesktopSorting";

//TODO: придумать лучшую реализацию локал стораджа
export const Sorting = () => {
    const [isInstructionHide, setIsInstructionHide] = useState(true)
    const translates = useTranslate()
    const isMobile = useAppSelector(state => state.appReducer.isMobile)
    const info = useAppSelector(state => state.cardSortingReducer.info)
    const project_id = Number(useParams().project_id)
    const group_state = useAppSelector(state => state.cardSortingReducer.group_state)
    const uploadTempStateIntervalRef = useRef();

    useEffect(() => {
        const projectDataObject = getObjectFromLocalStorage(String(project_id))

        if (projectDataObject.isSortingInstructionConfirmed) setIsInstructionHide(true)
        else setIsInstructionHide(false)

        // @ts-ignore
        uploadTempStateIntervalRef.current = setInterval(() => {
            saveSorting(group_state, project_id, false).then(() => console.log('Sorting state saved'))
        }, 1000 * 60)

        return () => {
            clearTimeout(uploadTempStateIntervalRef.current);
            uploadTempStateIntervalRef.current = undefined;
        };
    }, [])

    return (
        <>
            {!isInstructionHide && <InstructionBlock
                title={info.sorting_instruction_title}
                content={isMobile ? info.mobile_sorting_instruction_text : info.sorting_instruction_text}
                confirm={{
                    handler: () => {
                        setIsInstructionHide(true)

                        const projectDataObject = getObjectFromLocalStorage(String(project_id))
                        saveObjectInLocalStorage(String(project_id), {
                            ...projectDataObject,
                            isSortingInstructionConfirmed: true
                        })
                    },
                    confirmationText: translates.gotItButton
                }}
            />}
            {isInstructionHide && <DesktopSorting showInstruction={() => setIsInstructionHide(false)}/>}
        </>
    );
};