import React, {FC, MouseEventHandler} from 'react';
import {Button, Modal, Portal} from "../../UX";
// @ts-ignore
import styles from './AlertModalStyles.module.css'
import classNames from "classnames";

const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

interface AlertModalProps {
    children: string
    onSubmit: MouseEventHandler<HTMLButtonElement>
    submitText: string
    pClassName?: string
    isPending?: boolean
}

export const AlertModal:FC<AlertModalProps> = (props) => {
    const { children, onSubmit, submitText, pClassName, isPending = false } = props

    return (
        <Portal>
            <Modal>
                {isHTML(children) && <p
                    className={classNames(styles.modalText, pClassName)}
                    dangerouslySetInnerHTML={{__html: children}}
                />}
                {!isHTML(children) && <p className={classNames(styles.modalText, pClassName)}>{children}</p>}

                <Button onClick={onSubmit} variant="outlined" isPending={isPending}>{submitText}</Button>
            </Modal>
        </Portal>
    );
};