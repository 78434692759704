import {ErrorMessages, Pages} from "./types";
import {UserRole} from "../store/appReducer/appReducerTypes";
import {$authHost} from "../axios";

export const getErrorUrl = (error: string) => {
    switch (error) {
        case ('Request failed with status code 401'):
            return `${Pages.error}/${ErrorMessages.AUTHORIZATION_FAILED}`
        case ('Request failed with status code 403'):
            return `${Pages.error}/${ErrorMessages.ACCESS_DENIED}`
        default:
            return `${Pages.error}/${ErrorMessages.SOMETHINGS_WRONG}`
    }
}

export const readJwtPayload = (token: string): {
    sub: string,
    name: string,
    exp: number,
    user_role: UserRole
    client_id: number
} => {
    const tokenParts = token.split('.');
    return JSON.parse(atob(tokenParts[1]));
}

export const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)
};

export const downloadFileFromServer = async (file_url: string, filename: string) => {
    try {
        const response = await $authHost.get(file_url, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error(error);
    }
};

export const getObjectFromLocalStorage = (key: string) => {
    const object = localStorage.getItem(key)

    if (object) {
        return JSON.parse(object)
    } else {
        return {}
    }
}

export const saveObjectInLocalStorage = (key: string, object: Object) => localStorage.setItem(key, JSON.stringify(object))

export const isCurrentPageAuth = () => window.location.pathname.includes(`${Pages.auth}/`)

export const isTokenInStorage = () => !!localStorage.getItem('token')
