import React, {FC, ReactNode} from 'react';
// @ts-ignore
import styles from './InfoBlockStyles.module.css'

interface InfoBlockProps {
    header: string;
    children: string | ReactNode
}

export const InfoBlock: FC<InfoBlockProps> = (props) => {
    const { header, children } = props

    return (
        <div className={styles.infoBlock}>
            <h2 className={styles.header}>{header}</h2>
            <hr />
            <div className={styles.content}>
                {typeof children === 'string' ? <p className={styles.defaultTextStyles}>{children}</p> : children}
            </div>
        </div>
    );
};
