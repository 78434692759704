import React, {FC, useEffect, useRef, useState} from 'react';
// @ts-ignore
import styles from './HintButtonStyles.module.css'
import {HintIcon, IconButton} from "../../UX";

interface HintButtonProps {
    onClick?: (e: any) => void
    children?: string
}

export const HintButton: FC<HintButtonProps> = (props) => {
    const { onClick, children } = props
    const [width, setWidth] = useState<string | number>('50rem');
    const hintNotificationTextRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        if (hintNotificationTextRef.current) {
            const textWidth = hintNotificationTextRef.current.offsetWidth + 2;
            setWidth(textWidth);
        }

        setTimeout(() => {
            setWidth('0')
        }, 2500)
    }, [])

    return (
        <div className={styles.hintButtonContainer}>
            {children && <div className={styles.hintNotification} style={{width, opacity: width === '10000rem' ? '0' : '1'}}>
                <p className={styles.hintNotificationText} ref={hintNotificationTextRef}>
                    {children}
                </p>
            </div>}
            <IconButton onClick={onClick} className={styles.hintIconButton}>
                <HintIcon />
            </IconButton>
        </div>
    );
};