import {Language} from "../store/appReducer/appReducerTypes";

export enum Pages {
    main = '/project',
    auth = '/auth',
    admin = '/client_admin',
    card_sorting_admin = '/card_sorting_admin',
    superadmin_page = '/superadmin_page',
    error = '/error'
}

export interface AppInfoIn {
    language: Language,
}

export enum ErrorMessages {
    SOMETHINGS_WRONG = 'somethings_wrong',
    AUTHORIZATION_FAILED = 'authorization_failed',
    ACCESS_DENIED = 'access_denied',
    NO_ACCESS = 'no_access'
}

export interface Route {
    path: string
    element: () => JSX.Element
}