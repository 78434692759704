import React, {FC, MouseEvent, useEffect, useRef, useState} from 'react';
// @ts-ignore
import styles from '../../../CardSortingStyles.module.css'
import {ArrowBackIcon, CrossIcon} from "../../icons";
import {CardInGroup} from "../CardInGroup";
import {ConfirmModal, MouseHint} from "../../../../../../system/components";
import {useTranslate} from "../../../translates/useTranslate";
import SimpleBar from "simplebar-react";
import classNames from "classnames";
import './ScrollbarStyles.css'
import {
    Card,
    Group as GroupType,
} from "../../../reducers/cardSortingReducer/cardCortingReducerTypes";
import {useAppDispatch, useAppSelector} from "../../../../../../../store/store";
import {cardSortingSlice} from "../../../reducers/cardSortingReducer/cardSortingReducer";
import {useToggle} from "../../../../../../../common/hooks";

interface GroupProps {
    group: GroupType
}

const countRatedCards = (cards: Card[]) => {
    let count = 0

    cards.forEach(card => {
        if (card.grade_status !== 'unset')
            count++;
    })

    return count
}

export const Group: FC<GroupProps> = (props) => {
    const { id, name, cards } = props.group

    const translates = useTranslate()
    const dispatch = useAppDispatch()
    const renameGroup = cardSortingSlice.actions.renameGroup
    const deleteGroup = cardSortingSlice.actions.deleteGroup
    const movePickedCardsToGroup = cardSortingSlice.actions.movePickedCardsToGroup
    const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement | HTMLTextAreaElement> | null>()
    const [isNameHintShow, setIsNameHintShow] = useState(false)
    const [currentHint, setCurrentHint] = useState<'delete' | 'name' | 'like all' | 'dislike all'>('delete')
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const deleteModal = useToggle(false)
    const isAnyCardPicked = useAppSelector(state => state.cardSortingReducer.group_state.pickedCards.cards).length !== 0

    useEffect(() => {
        if (textareaRef.current) {
            if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
                setIsNameHintShow(true)
            } else {
                setIsNameHintShow(false)
            }
        }
    }, [name])

    const groupClickHandler = () => dispatch(movePickedCardsToGroup(id))
    const ratedCardCounter = countRatedCards(cards)
    const isAllRated = ratedCardCounter === cards.length && cards.length !== 0

    return (
        <div
            className={styles.group}
            style={{ cursor: isAnyCardPicked ? 'pointer' : 'unset' }}
            onClick={groupClickHandler}
        >
            {deleteModal.state && <ConfirmModal
                onConfirm={() => dispatch(deleteGroup(id))}
                onCancel={deleteModal.toggleValue}
                confirmText={translates.yes}
                cancelText={translates.no}
            >
                {translates.confirmDeleteGroup}
            </ConfirmModal>}

            {hintEvent && currentHint === 'delete' && <MouseHint event={hintEvent}>
                {translates.deleteGroupHint}
            </MouseHint>}
            {hintEvent && isNameHintShow && currentHint === 'name' && <MouseHint event={hintEvent}>
                {name}
            </MouseHint>}

            <div className={styles.groupHeadingContainer}>
                <textarea
                    onMouseEnter={() => setCurrentHint('name')}
                    onMouseMove={setHintEvent}
                    onMouseLeave={() => {
                        setHintEvent(null)
                        setCurrentHint('delete')
                    }}
                    ref={textareaRef}
                    value={name}
                    onChange={e => dispatch(renameGroup({id, value: e.target.value}))}
                    className={styles.groupHeading}
                />
                <div
                    onMouseMove={(e) => setHintEvent(e)}
                    onMouseLeave={() => setHintEvent(null)}
                    className={styles.cursorPointer}
                    onClick={deleteModal.toggleValue}
                >
                    <ArrowBackIcon className={styles.arrowBack}/>
                </div>
            </div>

            <div className={styles.countersContainer}>
                <div className={classNames(styles.counterContainer, isAllRated && styles.completedCounterContainer)}>
                    <span className={classNames(styles.countNumber, isAllRated && styles.completedCountNumber)}>
                        {cards.length} {translates.groupCardCounter}
                    </span>
                </div>

                <div className={classNames(styles.counterContainer, isAllRated && styles.completedCounterContainer)}>
                    <span className={classNames(styles.countNumber, isAllRated && styles.completedCountNumber)}>
                        {ratedCardCounter} {translates.groupRatedCardCounter}
                    </span>
                </div>
            </div>

            <div className={classNames(styles.groupCardsScrollableContainer, 'thinScrollbar')}>
                <SimpleBar style={{maxHeight: '100%', width: '100%'}} autoHide={false}>
                    <div className={styles.groupCardContainer}>
                        {cards.map(card => <CardInGroup key={card.id} card={card} groupId={id}/>)}
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};