import React, {FC} from 'react';
// @ts-ignore
import styles from './InputStyles.module.css'
import classNames from "classnames";

interface InputProps {
    isTextareaView?: boolean
    label?: string
    labelClassName?: string
    inputClassName?: string
    containerClassName?: string
    placeholder?: string
    value?: string
    type?: 'number' | 'text'
    onChange?: (e: any) => void
    onBlur?: (e: any) => void
}

export const Input:FC<InputProps> = (props) => {
    const {
        isTextareaView = false,
        label,
        labelClassName,
        inputClassName,
        containerClassName,
        placeholder,
        value = '',
        type = 'text',
        onChange,
        onBlur
    } = props

    return (
        <div className={classNames(styles.inputContainer, containerClassName)}>
            {label && <p className={classNames(styles.label, labelClassName)}>{label}</p>}

            {isTextareaView && <textarea
                className={classNames(styles.input, inputClassName)}
                placeholder={placeholder ? placeholder : ''}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />}

            {!isTextareaView && <input
                className={classNames(styles.input, inputClassName)}
                placeholder={placeholder ? placeholder : ''}
                value={value}
                type={type}
                onChange={onChange}
                onBlur={onBlur}
            />}
        </div>
    );
};