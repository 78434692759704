import React, {FC, MouseEvent, useState} from 'react';
// @ts-ignore
import styles from "../CardSortingAdminStyles.module.css";
import {copyToClipboard} from "../../../../../common/utils";
import {Pages} from "../../../../../common/types";
import {DeleteUserIcon} from "./icons/DeleteUserIcon";
import {UserRowIn} from "../types";
import {useTranslate} from "../translates/useTranslate";
import {SettingsIcon} from "./SettingsIcon";
import {useToggle} from "../../../../../common/hooks";
import {ConfirmModal, MouseHint} from "../../../../system/components";
import {UserSettings} from "./UserSettings";
import {deleteUser} from "../http";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../../store/store";
import {usersSlice} from "../reducers/usersReducer";

interface UserRowProps {
    user: UserRowIn
    statusFilter: string
    projectFilter: number
}

export const UserRow: FC<UserRowProps> = (props) => {
    const { user, statusFilter, projectFilter } = props

    const dispatch = useDispatch()
    const actions = usersSlice.actions
    const [hintEvent, setHintEvent] = useState<MouseEvent | null>()
    const [currentHint, setCurrentHint] = useState('')
    const translates = useTranslate()
    const isDeletePending = useToggle(false)
    const deleteModal = useToggle(false)
    const settingsModal = useToggle(false)

    const copyAuthLink = (id: number, isAdmin: boolean) => {
        copyToClipboard(
        isAdmin ?
            `${window.location.origin}${Pages.auth}/${user.registration_token}`
            :
            `${window.location.origin}${Pages.auth}/${user.registration_token}/${id}`
        ).then(() => setCurrentHint(translates.userManager.linkCopied))
    }

    const deleteUserHandler = () => {
        isDeletePending.toggleValue()
        deleteUser(user.id).then(() => {
            isDeletePending.toggleValue()
            dispatch(actions.deleteUser(user.id))
        })
    }

    const filteredProject = user.projects
        .filter(project => statusFilter ? (project.status === statusFilter) : true)
        .filter(project => projectFilter ? project.id === projectFilter : true)

    return (
        <div className={styles.userRow} key={user.id}>
            {deleteModal.state && <ConfirmModal
                onConfirm={deleteUserHandler}
                onCancel={deleteModal.toggleValue}
                confirmText={translates.confirmButton}
                cancelText={translates.cancelButton}
                isPending={isDeletePending.state}
            >
                {translates.userManager.confirmDeleteUserMessage}
            </ConfirmModal>}

            {settingsModal.state && <UserSettings user={user} onClose={settingsModal.toggleValue}/>}

            {hintEvent && <MouseHint event={hintEvent}>
                {currentHint}
            </MouseHint>}

            <div className={styles.controlsContainer}>
                <DeleteUserIcon
                    className={styles.controlIcon}
                    onClick={deleteModal.toggleValue}
                    onMouseEnter={() => setCurrentHint(translates.userManager.deleteUserHint)}
                    onMouseMove={(e) => setHintEvent(e)}
                    onMouseLeave={() => setHintEvent(null)}
                />
                <SettingsIcon
                    className={styles.controlIcon}
                    onClick={settingsModal.toggleValue}
                    onMouseEnter={() => setCurrentHint(translates.userManager.openSettingsHint)}
                    onMouseMove={(e) => setHintEvent(e)}
                    onMouseLeave={() => setHintEvent(null)}
                />
            </div>

            <p>{user.is_admin ? user.email + ` (${translates.userManager.admin})` : user.email}</p>
            <p>{user.name ? user.name : translates.userManager.notAuthStatus}</p>
            <div className={styles.projects}>
                {filteredProject.map(project => <p
                    key={project.id}
                    className={styles.projectRow}
                    onClick={() => copyAuthLink(project.id, user.is_admin)}
                    onMouseEnter={() => setCurrentHint(translates.userManager.clickToCopyLinkHint)}
                    onMouseMove={(e) => setHintEvent(e)}
                    onMouseLeave={() => setHintEvent(null)}
                >
                    {project.name} ({project.status})
                </p>)}
            </div>
        </div>
    );
};