import React from 'react';
// @ts-ignore
import styles from './CardSortingAdminPageStyles.module.css'
// @ts-ignore
import commonStyles from '../CenterLayout.module.css'
import {CardSortingAdmin} from "../../project/modules";

export const CardSortingAdminPage = () => {
    return (
        <div className={styles.cardSortingAdminPage}>
            <CardSortingAdmin />
        </div>
    );
};