import React, {FC, useState} from 'react';
import {BaseProject, UserProject, UserRowIn} from "../types";
import {Button, Checkbox, Input, Modal, Portal} from "../../../../system/UX";
// @ts-ignore
import styles from "../CardSortingAdminStyles.module.css";
import {useTranslate} from "../translates/useTranslate";
import {useAppSelector} from "../../../../../store/store";
import {CrossIcon} from "../../CardSorting/components/icons";
import {useToggle} from "../../../../../common/hooks";
import {ConfirmModal} from "../../../../system/components";
import {saveUserSettings} from "../http";
import {useDispatch} from "react-redux";
import {usersSlice} from "../reducers/usersReducer";

interface UserSettingsProps {
    user: UserRowIn
    onClose: () => void
}

export const UserSettings: FC<UserSettingsProps> = (props) => {
    const { user, onClose } = props

    const dispatch = useDispatch()
    const actions = usersSlice.actions
    const translate = useTranslate()
    const [userName, setUserName] = useState(user.name ? user.name : '')
    const [userEmail, setUserEmail] = useState(user.email)
    const [isAdmin, setIsAdmin] = useState(user.is_admin)
    const confirmSave = useToggle(false)
    const savePending = useToggle(false)

    const [pickedProjects, setPickedProjects] = useState<UserProject[]>(user.projects)

    const projects = useAppSelector(state => state.usersReducer.projects)

    const toggleProject = (currentProject: UserProject) => {
        for (let i = 0; i < pickedProjects.length; i++) {
            if (pickedProjects[i].id === currentProject.id) {
                return setPickedProjects(pickedProjects.filter(project => project.id !== currentProject.id))
            }
        }

        setPickedProjects(pickedProjects => [...pickedProjects, currentProject])
    }

    const isChecked = (currentProject: BaseProject) => {
        for (let i = 0; i < pickedProjects.length; i++) {
            if (pickedProjects[i].id === currentProject.id) {
                return true
            }
        }
        return false
    }

    const saveSettingsHandler = () => {
        savePending.toggleValue()

        const dataForSaving = {
            user_id: user.id,
            is_admin: isAdmin,
            email: userEmail,
            name: userName
        }

        saveUserSettings({
            ...dataForSaving,
            project_ids: pickedProjects.map(project => project.id)
        }).then((data) => {
            savePending.toggleValue()
            confirmSave.toggleValue()
            dispatch(actions.updateUser({
                ...dataForSaving,
                projects: pickedProjects
            }))
            onClose()
        })
    }

    return (
        <Portal>
            <Modal>
                <div className={styles.userSettings}>
                    {confirmSave.state && <ConfirmModal
                        onConfirm={saveSettingsHandler}
                        onCancel={confirmSave.toggleValue}
                        confirmText={translate.confirmButton}
                        cancelText={translate.cancelButton}
                    >
                        {translate.userManager.confirmSaveUserSettings}
                    </ConfirmModal>}

                    <h3 className={styles.sortSettingsHeader}>{translate.userManager.userSettingsTitle}</h3>
                    <div className={styles.closeUserSettings} onClick={onClose}>
                        <CrossIcon />
                    </div>
                    <div className={styles.checkboxField} onClick={() => setIsAdmin(!isAdmin)}>
                        <Checkbox isChecked={isAdmin} isMinimize={true}/>
                        <p>{translate.userManager.isAdmin}</p>
                    </div>
                    <Input
                        placeholder={translate.userManager.name}
                        label={translate.userManager.name}
                        containerClassName={styles.userOrProjectInputContainer}
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                    <Input
                        placeholder={translate.userManager.email}
                        label={translate.userManager.email}
                        containerClassName={styles.userOrProjectInputContainer}
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                    />
                    <p>{translate.userManager.userProjects}</p>
                    <div>
                        {projects.map(project => <div
                            key={project.id}
                            className={styles.checkboxField}
                            onClick={() => toggleProject({
                                ...project,
                                status: 'project not opened'
                            })}
                        >
                            <Checkbox isChecked={isChecked(project)} isMinimize={true}/>
                            <p>{project.name}</p>
                        </div>)}
                    </div>
                    <Button className={styles.saveUserSettingsButton} onClick={confirmSave.toggleValue}>
                        {translate.sortingSettings.saveButton}
                    </Button>
                </div>
            </Modal>
        </Portal>
    );
};