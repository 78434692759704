import React, {FC, useEffect, useState} from 'react';
// @ts-ignore
import styles from '../CardSortingAdminStyles.module.css'
import {useTranslate} from "../translates/useTranslate";
import {Button, Input, LargeModal, Portal, Preloader} from "../../../../system/UX";
import {CardEditorTrashIcon} from "./icons/CardEditorTrashIcon";
import {CopyCardIcon} from "./icons/CopyCardIcon";
import {CardForEdit} from "../types";
import {AlertModal, ConfirmModal} from "../../../../system/components";
import {CrossIcon} from "../../CardSorting/components/icons";
import {getCards, saveCards} from "../http";
import {useToggle} from "../../../../../common/hooks";

interface PreModerationModalProps {
    onClose: () => void
    projectId: number
}

const findMinimumFreeNegativeId = (ids: number[]): number => {
    ids.sort((a, b) => a - b);
    let minFreeNegativeId = -1;

    for (const id of ids) {
        while (minFreeNegativeId >= id) {
            minFreeNegativeId--;
        }

        if (id < minFreeNegativeId) {
            return id;
        }
    }

    return minFreeNegativeId;
};

//TODO: правильно порефакторить стили и названия
export const PreModerationModal: FC<PreModerationModalProps> = (props) => {
    const translates = useTranslate()
    const [cards, setCards] = useState<CardForEdit[]>([])
    const [cardToDelete, setCardToDelete] = useState<number | null>(null)
    const [isCardsLoading, setIsCardLoading] = useState(true)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isPendingSaving, setIsPendingSaving] = useState(false)

    useEffect(() => {
        setIsCardLoading(true)
        getCards(props.projectId).then(data => {
            setCards(data)
            setIsCardLoading(false)
        })
    }, [])

    const changeCardHandler = (index: number, text: string) => {
        setCards(cards => {
            const array = [...cards]
            array[index].text = text
            return array
        })
    }

    const deleteCard = () => {
        setCards(cards => {
            const array = [...cards]
            if (cardToDelete !== null) {
                array.splice(cardToDelete, 1)
            }
            return array
        })
        setCardToDelete(null)
    }

    const copyCard = (index: number) => {
        setCards(cards => {
            const array = [...cards]
            array.splice(index, 0, {
                id: findMinimumFreeNegativeId(cards.filter(card => card.id < 0).map(card => card.id)),
                text: array[index].text,
                author_id: array[index].author_id,
                question_id: array[index].question_id
            })
            return array
        })
    }

    const closeSuccessModalAndPreModeration = () => {
        setIsSuccessModalOpen(false)
        props.onClose()
    }

    const saveCardsHandler = () => {
        setIsPendingSaving(true)
        saveCards(props.projectId, cards).then(() => {
            setIsPendingSaving(false)
            setIsSuccessModalOpen(true)
        })
    }

    return (
        <Portal>
            <LargeModal>
                <div className={styles.preModerationModal}>
                    {cardToDelete !== null && <ConfirmModal
                        confirmText={translates.confirmButton}
                        cancelText={translates.cancelButton}
                        onCancel={() => setCardToDelete(null)}
                        onConfirm={deleteCard}
                    >
                        {translates.sortingSettings.cardEditorConfirmDelete}
                    </ConfirmModal>}

                    {isSuccessModalOpen && <AlertModal
                        onSubmit={closeSuccessModalAndPreModeration}
                        submitText={translates.gotItButton}
                    >
                        asd
                    </AlertModal>}

                    <div className={styles.closeSettings} onClick={props.onClose}>
                        <CrossIcon />
                    </div>

                    <h3 className={styles.sortSettingsHeader}>{translates.sortingSettings.cardEditorHeader}</h3>

                    {isCardsLoading && <Preloader />}

                    {!isCardsLoading && <>
                        {cards.map((card, index) => <div key={card.id} className={styles.cardEditor}>
                            <span>{index + 1}.</span>
                            <Input
                                isTextareaView={true}
                                inputClassName={styles.cardTextEditor}
                                value={card.text}
                                onChange={(e) => {changeCardHandler(index, e.target.value)}}
                            />
                            <div className={styles.cardControlsContainer}>
                                <CardEditorTrashIcon onClick={() => setCardToDelete(index)}/>
                                <CopyCardIcon onClick={() => copyCard(index)}/>
                            </div>
                        </div>)}

                        {cards.length === 0 && <p className={styles.cardEditorPlaceholder}>
                            {translates.sortingSettings.cardEditorPlaceholder}
                        </p>}

                        {cards.length !== 0 && <Button onClick={saveCardsHandler} isPending={isPendingSaving}>
                            {translates.sortingSettings.saveButton}
                        </Button>}
                    </>}
                </div>
            </LargeModal>
        </Portal>
    );
};