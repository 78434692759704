// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HintButtonStyles_hintButtonContainer__y\\+dey {\n    display: flex;\n    align-items: center;\n    position: relative;\n}\n\n.HintButtonStyles_hintNotification__CC3g2 {\n    position: absolute;\n    z-index: 2;\n    left: 1.2rem;\n    background-color: white;\n    border: 0.0625rem solid black;\n    border-left: none;\n    border-top-right-radius: 3rem;\n    border-bottom-right-radius: 3rem;\n    transition: width .2s;\n    height: 2.45rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;\n}\n\n.HintButtonStyles_hintNotificationText__25aMs {\n    white-space: nowrap;\n    padding-left: 1.7rem;\n    padding-right: 0.7rem;\n}\n\n.HintButtonStyles_hintIconButton__Qn4jn {\n    z-index: 2;\n}", "",{"version":3,"sources":["webpack://./src/project/system/components/HintButton/HintButtonStyles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,6BAA6B;IAC7B,iBAAiB;IACjB,6BAA6B;IAC7B,gCAAgC;IAChC,qBAAqB;IACrB,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".hintButtonContainer {\n    display: flex;\n    align-items: center;\n    position: relative;\n}\n\n.hintNotification {\n    position: absolute;\n    z-index: 2;\n    left: 1.2rem;\n    background-color: white;\n    border: 0.0625rem solid black;\n    border-left: none;\n    border-top-right-radius: 3rem;\n    border-bottom-right-radius: 3rem;\n    transition: width .2s;\n    height: 2.45rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;\n}\n\n.hintNotificationText {\n    white-space: nowrap;\n    padding-left: 1.7rem;\n    padding-right: 0.7rem;\n}\n\n.hintIconButton {\n    z-index: 2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hintButtonContainer": "HintButtonStyles_hintButtonContainer__y+dey",
	"hintNotification": "HintButtonStyles_hintNotification__CC3g2",
	"hintNotificationText": "HintButtonStyles_hintNotificationText__25aMs",
	"hintIconButton": "HintButtonStyles_hintIconButton__Qn4jn"
};
export default ___CSS_LOADER_EXPORT___;
