import React, {useEffect} from 'react'
import AppRouter from './router/AppRouter'
import {BrowserRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {appSlice} from "./store/appReducer/appReducer";

function App() {
	const dispatch = useDispatch();
	const setIsMobile = appSlice.actions.setIsMobile

	useEffect(() => {
		const handleResize = () => {
			dispatch(setIsMobile(window.innerWidth < 1280));
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [dispatch]);


	return (
		<div>
			<BrowserRouter>
				<AppRouter />
			</BrowserRouter>
		</div>
	)
}

export default App
