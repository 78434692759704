import React from 'react';
// @ts-ignore
import styles from '../../CardSortingStyles.module.css'
import CardInBank from "./CardInBank";
import SimpleBar from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';
import {useAppSelector} from "../../../../../../store/store";
import {useTranslate} from "../../translates/useTranslate";
import classNames from "classnames";

export const BankOfCards = () => {
    const translate = useTranslate()
    const bank = useAppSelector(state => state.cardSortingReducer.group_state.bank)

    return (
        <div className={styles.bankOfCards}>
            {bank.length !== 0 && <SimpleBar style={{maxHeight: '100%', width: '100%'}} autoHide={false}>
                <div className={styles.bankOrBinCardContainer}>
                    {bank && bank.map(card => <CardInBank key={card.id} card={card}/>)}
                </div>
            </SimpleBar>}
            {!bank.length && <p className={classNames(styles.containerPlaceholder, styles.bankPlaceholder)}>
                {translate.bankPlaceholder}
            </p>}
        </div>
    );
};