import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppState, Language, User} from "./appReducerTypes";
import {AppInfoIn} from "../../common/types";
import {getLanguage} from "./ActionCreators";

const initialState: AppState = {
    projectInfo: {
        language: 'EN',
        isLoading: false,
        fetchError: ''
    },
    isMobile: false
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload
        },

        setLanguage: (state, action: PayloadAction<Language>) => {
            state.projectInfo.language = action.payload
        },

        setIsMobile: (state, action: PayloadAction<boolean>) => {
            state.isMobile = action.payload
        },
    },

    extraReducers: {
        [getLanguage.pending.type]: (state) => {
            state.projectInfo.isLoading = true
        },

        [getLanguage.rejected.type]: (state, action: PayloadAction<string>) => {
            state.projectInfo.isLoading = false
            state.projectInfo.fetchError = action.payload
        },

        [getLanguage.fulfilled.type]: (state, action: PayloadAction<AppInfoIn>) => {
            state.projectInfo.isLoading = false
            state.projectInfo.language = action.payload.language
            state.projectInfo.fetchError = ''
        },
    }
})

export default appSlice.reducer