import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Card, CardSortingState, GroupState, LikeStatus, UserSoringStatus} from "./cardCortingReducerTypes";
import {
    findMissingNumber,
    setGradeCorrectly,
} from "../../utils";
import {SortingStateIn} from "../../types";
import {getObjectFromLocalStorage, saveObjectInLocalStorage} from "../../../../../../common/utils";

//TODO: придумать лучшее решение по отображению нужного проекта (по project_id)
const initialState: CardSortingState = {
    info: {
        instruction_text: 'Instruction text',
        instruction_title: 'Instruction title',
        sorting_instruction_title: 'Sorting instruction title',
        sorting_instruction_text: 'Sorting instruction text',
        mobile_sorting_instruction_text: 'Mobile sorting instruction text',
        neutral_grade_hint: 'Neutral grade hint',
        like_hint: 'Like hint',
        dislike_hint: 'Dislike hint',
        like_limit: 0,
        dislike_limit: 0,
    },
    project_id: 0,
    group_state: {
        pickedCards: {
            group: 'bank',
            cards: []
        },
        total_number_of_cards: 0,
        groups: [],
        bank: [],
        bin: [],
    },

    isLoading: false,
    fetchError: '',
    user_status: UserSoringStatus.COLLECTING_CARDS
}

export const cardSortingSlice = createSlice({
    name: 'cardSorting',
    initialState,
    reducers: {
        setState: (state, action: PayloadAction<GroupState>) => {
            state.group_state = action.payload
        },

        setSortingState: (state, action: PayloadAction<SortingStateIn>) => {
            state.isLoading = false
            state.info = action.payload.info
            state.project_id = action.payload.project_id
            if (action.payload.cards) {
                state.group_state.bank = action.payload.cards
                state.group_state.total_number_of_cards = action.payload.cards.length
            }

            state.user_status = action.payload.user_status
        },

        togglePickedCard: (state, action: PayloadAction<{ group: 'bank' | number, cardId: number }>) => {
            if (state.group_state.pickedCards.group !== action.payload.group) {
                state.group_state.pickedCards.group = action.payload.group
                state.group_state.pickedCards.cards = [action.payload.cardId]
            } else {
                if (state.group_state.pickedCards.cards.includes(action.payload.cardId)) {
                    state.group_state.pickedCards.cards = state.group_state.pickedCards.cards.filter((id) => {
                        return id !== action.payload.cardId
                    })
                } else {
                    state.group_state.pickedCards.cards.push(action.payload.cardId)
                }
            }
        },

        movePickedCardsToGroup: (state, action: PayloadAction<number>) => {
            if (state.group_state.pickedCards.group !== action.payload && state.group_state.pickedCards.cards.length !== 0) {
                const cardsToMove: Card[] = []

                state.group_state.bank.forEach(card => {
                    if (state.group_state.pickedCards.cards.includes(card.id))
                        cardsToMove.push(card)
                })
                state.group_state.bank = state.group_state.bank.filter(card => !state.group_state.pickedCards.cards.includes(card.id))

                state.group_state.groups.forEach(group => {
                    group.cards.forEach(card => {
                        if (state.group_state.pickedCards.cards.includes(card.id))
                            cardsToMove.push(card)
                    })
                    group.cards = group.cards.filter(card => !state.group_state.pickedCards.cards.includes(card.id))
                })

                state.group_state.groups.forEach(group => {
                    if (group.id === action.payload)
                        group.cards = [...group.cards, ...cardsToMove]
                })

                state.group_state.pickedCards.cards = []

                const projectDataObject = getObjectFromLocalStorage(String(state.project_id))
                saveObjectInLocalStorage(String(state.project_id), {
                    ...projectDataObject,
                    groupState: state.group_state
                })
            }
        },

        // TODO: декомпозировать логику переноса пикнутых карточек в группу
        createGroupAndMovePickedCards: (state, action: PayloadAction<string>) => {
            const id = findMissingNumber(state.group_state.groups.map(group => group.id))

            state.group_state.groups.unshift({
                id,
                name: action.payload + ' ' + (state.group_state.groups.length + 1),
                cards: []
            })

            const cardsToMove: Card[] = []

            state.group_state.bank.forEach(card => {
                if (state.group_state.pickedCards.cards.includes(card.id))
                    cardsToMove.push(card)
            })
            state.group_state.bank = state.group_state.bank.filter(card => !state.group_state.pickedCards.cards.includes(card.id))

            state.group_state.groups.forEach(group => {
                group.cards.forEach(card => {
                    if (state.group_state.pickedCards.cards.includes(card.id))
                        cardsToMove.push(card)
                })
                group.cards = group.cards.filter(card => !state.group_state.pickedCards.cards.includes(card.id))
            })

            state.group_state.groups.forEach(group => {
                if (group.id === id)
                    group.cards = [...group.cards, ...cardsToMove]
            })

            state.group_state.pickedCards.cards = []

            const projectDataObject = getObjectFromLocalStorage(String(state.project_id))
            saveObjectInLocalStorage(String(state.project_id), {
                ...projectDataObject,
                groupState: state.group_state
            })
        },

        renameGroup: (state, action: PayloadAction<{id: number, value: string}>) => {
            state.group_state.groups.forEach(group => {
                if (group.id === action.payload.id) {
                    group.name = action.payload.value
                }
            })
        },

        deleteGroup: (state, action: PayloadAction<number>) => {
            state.group_state.groups = state.group_state.groups.filter(group => {
                if (group.id === action.payload) {
                    state.group_state.bank = group.cards.concat(state.group_state.bank)
                }

                return  group.id !== action.payload
            })

            const projectDataObject = getObjectFromLocalStorage(String(state.project_id))
            saveObjectInLocalStorage(String(state.project_id), {
                ...projectDataObject,
                groupState: state.group_state
            })
        },

        setGrade: (state, action: PayloadAction<{id: number, grade: LikeStatus}>) => {
            state.group_state.groups.forEach(group => {
                group.cards.forEach(card => {
                    if (card.id === action.payload.id) {
                        card.grade_status = setGradeCorrectly(action.payload.grade, card.grade_status)
                    }
                })
            })

            const projectDataObject = getObjectFromLocalStorage(String(state.project_id))
            saveObjectInLocalStorage(String(state.project_id), {
                ...projectDataObject,
                groupState: state.group_state
            })
        },

        moveFromGroupToBank: (state, action: PayloadAction<number>) => {
            state.group_state.groups.forEach(group => {
                group.cards.forEach(card => {
                    if (card.id === action.payload) {
                        state.group_state.bank.unshift(card)
                        group.cards = group.cards.filter(card => card.id !== action.payload)
                    }
                })
            })

            const projectDataObject = getObjectFromLocalStorage(String(state.project_id))
            saveObjectInLocalStorage(String(state.project_id), {
                ...projectDataObject,
                groupState: state.group_state
            })
        },

        moveFromBinToBank: (state, action: PayloadAction<number>) => {
            state.group_state.bin.forEach(card => {
                if (card.id === action.payload) {
                    state.group_state.bank.unshift(card)
                    state.group_state.bin = state.group_state.bin.filter(card => card.id !== action.payload)
                }
            })

            const projectDataObject = getObjectFromLocalStorage(String(state.project_id))
            saveObjectInLocalStorage(String(state.project_id), {
                ...projectDataObject,
                groupState: state.group_state
            })
        },

        moveFromBankToBin: (state, action: PayloadAction<number>) => {
            state.group_state.bank.forEach(card => {
                if (card.id === action.payload) {
                    state.group_state.bin.push(card)
                    state.group_state.bank = state.group_state.bank.filter(card => card.id !== action.payload)
                }
            })

            const projectDataObject = getObjectFromLocalStorage(String(state.project_id))
            saveObjectInLocalStorage(String(state.project_id), {
                ...projectDataObject,
                groupState: state.group_state
            })
        },

        createGroup: (state, action: PayloadAction<string>) => {
            const id = findMissingNumber(state.group_state.groups.map(group => group.id))

            state.group_state.groups.unshift({
                id,
                name: action.payload + ' ' + (state.group_state.groups.length + 1),
                cards: []
            })

            const projectDataObject = getObjectFromLocalStorage(String(state.project_id))
            saveObjectInLocalStorage(String(state.project_id), {
                ...projectDataObject,
                groupState: state.group_state
            })
        },

        setCardsFilled: (state) => {
            state.user_status = UserSoringStatus.WAITING_FOR_SORTING
        },

        finishSort: (state) => {
            state.user_status = UserSoringStatus.SORTING_FINISHED
        }
    },
})

export default cardSortingSlice.reducer