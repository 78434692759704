import {useEffect, useRef, useState} from "react";

export const useToggle = (initialValue: boolean) => {
    const [state, setState] = useState(initialValue)

    const toggleValue = () => setState(state => !state)

    return { state, toggleValue }
}

export const useBoolState = (initialValue: boolean) => {
    const [state, setState] = useState(initialValue)

    const setTrue = () => setState(true)
    const setFalse = () => setState(false)

    return { state, setTrue, setFalse }
}

type EventListener = (event: MouseEvent) => void;

export const useClickOutsideListenerWithRef = (handler: EventListener) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            handler(event);
        }
    };

    useEffect(() => {
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    });

    return ref;
};