import React, {FC, PropsWithChildren} from 'react';
// @ts-ignore
import styles from "./LargeModalStyles.module.css";

export const LargeModal:FC<PropsWithChildren> = (props) => {
    const { children } = props

    return (
        <div className={styles.modalBackground}>
            <div style={{ margin: "auto" }}>
                {children}
            </div>
        </div>
    );
};