import React from 'react';
// @ts-ignore
import styles from '../CenterLayout.module.css'
import {useParams} from "react-router-dom";
import {ErrorMessageBlock} from "../../project/modules";

export const ErrorPage = () => {
    const error_message = useParams().error

    return (
        <div className={styles.centerLayout}>
            {<ErrorMessageBlock errorMessage={error_message}/>}
        </div>
    );
};