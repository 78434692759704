import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../CardSortingAdminStyles.module.css";
import {Button, Input} from "../../../../system/UX";
import {addUser, getUserRows} from "../http";
import {useParams} from "react-router-dom";
import {arrayOfAdminUserSortingStatus} from "../types";
import {downloadFileFromServer} from "../../../../../common/utils";
import {AlertModal} from "../../../../system/components";
import {UserRole} from "../../../../../store/appReducer/appReducerTypes";
import {useToggle} from "../../../../../common/hooks";
import {Hyperlink} from "../../../../system/UX/Link";
import {useTranslate} from "../translates/useTranslate";
import {UserRow} from "./UserRow";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../../store/store";
import {usersSlice} from "../reducers/usersReducer";
import {SearchIcon} from "./icons/SearchIcon";


export const UserManagementControl = () => {
    const dispatch = useDispatch()
    const { users, projects } = useAppSelector(state => state.usersReducer)
    const actions = usersSlice.actions
    const translates = useTranslate()
    const [error, setError] = useState('')
    const client_id = Number(useParams().client_id)
    const isPending = useToggle(false)
    const [email, setEmail] = useState('')
    const [statusFilter, setStatusFilter] = useState('')
    const [projectFilter, setProjectFilter] = useState(0)
    const [searchFilter, setSearchFilter] = useState('')

    const addUserHandler = () => {
        isPending.toggleValue()
        addUser(email, UserRole.COMMON_USER, client_id).then((data) => {
            setEmail('')
            dispatch(actions.addUser(data))
        })
        .catch(() => {
            setEmail('')
            setError(translates.userManager.createUserError)
        })
        .finally(() => isPending.toggleValue())
    }

    const filteredUsers = users.filter(user => {
        if (!searchFilter) return true

        const isEmailIncluded = String(user.email).toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())
        const isNameIncluded = String(user.name).toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())
        return isEmailIncluded || isNameIncluded
    }).filter(user => {
        if (projectFilter === 0) return true

        let isUserHaveFilteredProject = false
        user.projects.forEach(project => {
            if (project.id === projectFilter) {
                isUserHaveFilteredProject = true
            }
        })

        return isUserHaveFilteredProject
    }).filter(user => {
        if (statusFilter === '' || statusFilter === 'admins') return true

        let isUserHaveFilteredStatus = false
        user.projects.forEach(project => {
            if (project.status === statusFilter) {
                isUserHaveFilteredStatus = true
            }
        })

        return isUserHaveFilteredStatus
    }).filter(user => {
        if (statusFilter !== 'admins') return true
        return user.is_admin
    })

    useEffect(() => {
        getUserRows(client_id).then((data) => {
            dispatch(actions.setUsers(data))
        })
    }, [])

    return (
        <div className={styles.userManagementContainer}>
            {error && <AlertModal onSubmit={() => setError('')} submitText={translates.gotItButton}>
                {error}
            </AlertModal>}
            <h3 className={styles.sortSettingsHeader}>{translates.userManager.userManagementTitle}</h3>
            <div className={styles.addUserOrProjectControl}>
                <Input
                    placeholder={translates.userManager.addUserPlaceholder}
                    label={translates.userManager.addUserLabel}
                    containerClassName={styles.userOrProjectInputContainer}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                    onClick={addUserHandler}
                    isPending={isPending.state}
                    className={styles.addUserOrProjectButtonStyles}
                >
                    {translates.userManager.addUserButton}
                </Button>
            </div>
            <div className={styles.filtersContainer}>
                <div className={styles.filters}>
                    <p>{translates.userManager.projectFilter}</p>
                    {projects.map(project => <button
                        key={project.id}
                        className={styles.filter}
                        style={{ backgroundColor: project.id === projectFilter ? '#FFC000' : 'white' }}
                        onClick={() => {
                            if (projectFilter !== project.id) {
                                setProjectFilter(project.id)
                            } else {
                                setProjectFilter(0)
                            }
                        }}
                    >
                        {project.name}
                    </button>)}
                </div>
                <div className={styles.filters}>
                    <p>{translates.userManager.statusFilter}</p>
                    {arrayOfAdminUserSortingStatus.map(status => <button
                        className={styles.filter}
                        key={status}
                        style={{ backgroundColor: status === statusFilter ? '#FFC000' : 'white' }}
                        onClick={() => {
                            if (statusFilter !== status) {
                                setStatusFilter(status)
                            } else {
                                setStatusFilter('')
                            }
                        }}
                    >
                        {status}
                    </button>)}
                </div>
            </div>
            <Hyperlink
                href="#"
                onClick={() => downloadFileFromServer(
                    `card_sorting_admin/generate_xlsx?client_id=${client_id}&project_id=${projectFilter}&status=${statusFilter}`,
                    'cards_info.xlsx'
                )}
            >
                {translates.userManager.downloadXLSX}
            </Hyperlink>
            <div className={styles.search}>
                <SearchIcon />
                <input
                    type="text"
                    placeholder={translates.userManager.searchByNameOrLogin}
                    className={styles.searchInput}
                    value={searchFilter}
                    onChange={(e) => setSearchFilter(e.target.value)}
                />
            </div>
            <div className={styles.userList}>
                {filteredUsers.sort((firstUser, secondUser) => secondUser.id - firstUser.id).map(user => <UserRow
                    user={user}
                    projectFilter={projectFilter}
                    statusFilter={statusFilter === 'admins' ? '' : statusFilter}
                    key={user.id}
                />)}
            </div>
        </div>
    );
};