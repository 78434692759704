import React from 'react';
// @ts-ignore
import styles from "../../CardSortingStyles.module.css";
import {BankOfCards} from "./BankOfCards";
import {SortingControls} from "../SortingControls";
import {Groups} from "./Groups";
import {InfoBlock} from "../../../../../system/UX";


//TODO: сделать транслейты для инструкций и хэдера банка карт
export const DesktopSorting = ({ showInstruction }: { showInstruction: () => void }) => {
    return (
        <>
            <div className={styles.sortingContainer}>
                <div className={styles.bankOfCardsContainer}>
                    <h1 className={styles.bankOfCardsHeader}>
                        <span className={styles.treasury}>treasury</span>
                        <p>all the ideas as cards</p>
                    </h1>
                    <BankOfCards />
                </div>
                <div className={styles.groupsAndControlsContainer}>
                    <SortingControls onClick={showInstruction}/>
                    <Groups />
                </div>
            </div>
            <div className={styles.instructions}>
                <InfoBlock header="Step 1">
                    <p className={styles.bottomHintTextStyles} style={{ width: '6rem' }}>Explore the <span className={styles.hintSpan}>TREASURY</span>, learn its concepts, find patterns, themes, and cards with similar meanings.</p>
                </InfoBlock>
                <InfoBlock header="Step 2">
                    <p className={styles.bottomHintTextStyles} style={{ width: '7rem' }}> Select cards united by one theme and press <span className={styles.hintSpan}>+ Add group</span>. The cards will be grouped on the right.</p>
                </InfoBlock>
                <InfoBlock header="Step 3">
                    <p className={styles.bottomHintTextStyles} style={{ width: '7.5rem' }}>Give meaningful name to the group and proceed to the next theme</p>
                    <p className={styles.bottomHintTextStyles} style={{ width: '7rem' }}>...or, create an empty group and move cards there by clicking them and the group title.</p>
                </InfoBlock>
                <InfoBlock header="Repeat steps 2-3 until all cards are sorted">
                    <p className={styles.bottomHintTextStyles} style={{ width: '10rem' }}>Sort cards by content, not context or sentiment. For example, group cards A and B if they discuss pollution, not because they were both good solutions but in different areas.</p>
                    <p className={styles.bottomHintTextStyles} style={{ width: '13rem' }}>Use the <span className={styles.hintSpan}>back arrow</span> to remove a group and move the cards back to the <span className={styles.hintSpan}>TREASURY</span>. However, don’t overthink, trust your gut. The ideal structure will emerge from combining everyone’s input later.</p>
                    <p className={styles.bottomHintTextStyles} style={{ width: '8rem' }}>Delete cards you don't understand. They're in the <span className={styles.hintSpan}>Trash bin</span>, and you can always restore them should you change your mind</p>
                </InfoBlock>
                <InfoBlock header="Step 4">
                    <p className={styles.bottomHintTextStyles} style={{ width: '11rem' }}><span className={styles.hintSpan}>Upvote</span> pressing issues, <span className={styles.hintSpan}>downvote</span> least pressing matters; <span className={styles.hintSpan}>dot</span> for medium importance or if uncertain. If there are progress bars with thumbs up and down on top of the screen, they indicate minimum votes of each side you should give.</p>
                </InfoBlock>
            </div>
        </>
    );
};