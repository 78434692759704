import React, {FC, MouseEvent} from 'react';
// @ts-ignore
import styles from './MouseHintStyles.module.css'
import {Portal} from "../../UX";

interface MouseHintProps {
    event: MouseEvent
    children: string
}

export const MouseHint: FC<MouseHintProps> = (props) => {
    const { event, children } = props

    return (
        <Portal>
            <div className={styles.mouseHint} style={{top: event.pageY, left: `calc(${event.pageX}px + 1rem)`}}>
                <p>{children}</p>
            </div>
        </Portal>
    );
};