import React from 'react';
// @ts-ignore
import styles from '../../CardSortingStyles.module.css'
import SimpleBar from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';
import {useAppSelector} from "../../../../../../store/store";
import {useTranslate} from "../../translates/useTranslate";
import classNames from "classnames";
import {Group} from "./Group/Group";

export const Groups = () => {
    const translate = useTranslate()
    const groups = useAppSelector(state => state.cardSortingReducer.group_state.groups)


    return (
        <div className={styles.groupsContainer}>
            {groups.length !== 0 && <SimpleBar style={{maxHeight: '100%', width: '100%'}} autoHide={false}>
                <div className={styles.scrollableGroupsContainer}>
                    {groups.map(group => <Group key={group.id} group={group}/>)}
                </div>
            </SimpleBar>}

            {!groups.length && <p className={classNames(styles.containerPlaceholder, styles.groupContainerPlaceholder)}>
                {translate.groupContainerPlaceholder}
            </p>}
        </div>
    );
};