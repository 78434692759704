import React, {useState, MouseEvent, FC, useRef} from 'react';
// @ts-ignore
import styles from '../../CardSortingStyles.module.css'
import {TrashIcon} from "../icons";
import {MouseHint} from "../../../../../system/components";
import {useTranslate} from "../../translates/useTranslate";
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {cardSortingSlice} from "../../reducers/cardSortingReducer/cardSortingReducer";
import {Card} from "../../reducers/cardSortingReducer/cardCortingReducerTypes";

export interface CardProps {
    card: Card
}


//TODO: Вынести в переменную текущий rem
const CardInBank: FC<CardProps> = (props) => {
    const { text, id } = props.card

    const dispatch = useAppDispatch()
    const { moveFromBankToBin, togglePickedCard } = cardSortingSlice.actions
    const translates = useTranslate()
    const [isTextHint, setIsTextHint] = useState(false)
    const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()
    const pickedCards = useAppSelector(state => state.cardSortingReducer.group_state.pickedCards)
    const textRef = useRef<HTMLParagraphElement>(null)

    const textHintHandler = (e: MouseEvent<HTMLParagraphElement>) => {
        if (textRef.current && textRef.current.offsetHeight > 60) {
            setHintEvent(e)
            setIsTextHint(true)
        }
    }

    return (
        <div
            className={classNames(styles.card, styles.cardInBank)}
            style={{ backgroundColor: pickedCards.cards.includes(id) ? 'silver' : 'white' }}
        >
            {hintEvent && <MouseHint event={hintEvent}>
                {isTextHint ? text : translates.trashMouseHint}
            </MouseHint>}
            <p
                onMouseMove={textHintHandler}
                onMouseLeave={() => setHintEvent(null)}
                className={classNames(styles.cardText, styles.cardInBankText)}
                onClick={() => dispatch(togglePickedCard({ group: 'bank', cardId: id }))}
            >
                <span ref={textRef}>{text}</span>
            </p>
            <div
                onMouseMove={(e) => {
                    setHintEvent(e)
                    setIsTextHint(false)
                }}
                onMouseLeave={() => setHintEvent(null)}
                className={styles.cursorPointer}
                onClick={() => dispatch(moveFromBankToBin(id))}
            >
                <TrashIcon className={styles.trashIcon}/>
            </div>
        </div>
    );
};

export default CardInBank;