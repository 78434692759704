import {Translates} from "./translates";

export const enTranslates: Translates = {
    gotItButton: 'Got it!',
    save: 'Save',
    cardsNotCollectedTittle: 'Thank you! We received your cards.',
    cardsNotCollectedText: 'Not everyone has completed the task yet. As soon as we receive their answers, we will open the next exercise. We will let you know via email.',
    cardInputPlaceholder: 'Write a card text...',
    addCardButton: 'Add card',
    removeCardButton: 'Remove card',
    trashMouseHint: 'Move to a trash bin',
    sendCardsButtonHint: 'Button will become active when you fill out all the cards',
    backMouseHint: 'Move back to bank',
    hintNotification: 'Click if you want to see instruction',
    mobileHintNotification: 'Click to see instruction',
    deleteGroupHint: 'Delete Group',
    confirmDeleteGroup: 'Are you sure you want to delete this group? All cards of this group will fit back into the bank.',
    binHeader: 'Unclear cards',
    sortingFinishedTittle: 'Thank you, we saved your answers.',
    sortingFinishedText: '',
    group: 'Group',
    groupCardCounter: 'cards',
    groupRatedCardCounter: 'rated',
    addGroupButton: 'Add group',
    finishSortButton: 'Finish sorting',
    cardCounter: {
        sorted: 'Sorted ',
        of: ' of ',
        cards: ' cards',
        liked: ' liked',
        disliked: ' disliked'
    },
    yes: 'Yes',
    no: 'No',
    sortingErrors: {
        notAllCardsSorted: 'You didn\'t sort all cards',
        emptyGroupName: 'There are empty group names. Please name them',
        binNameUsed: '"bin" is a system name. Please rename it',
        notAllLikesGiven: 'You didn\'t give the required number of likes',
        notAllDislikesGiven: 'You didn\'t give the required number of dislikes'
    },
    saveSortingConfirm: 'You really want to save your sorting?',
    setLikesRequest: 'In order to save the sorting, you need to put down the missing grades.',
    groupContainerPlaceholder: 'Select cards that are similar in meaning and press “add group” to place them together in one group.',
    bankPlaceholder: 'Congratulations! You have sorted all the cards. You can finish sorting.'
}