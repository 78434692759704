import {GradeStatus, Group, LikeStatus} from "./reducers/cardSortingReducer/cardCortingReducerTypes";

export function findMissingNumber(arr: number[]): number {
    arr.sort((a, b) => a - b);

    let missingNumber = 1;

    if (arr.length === 0) return missingNumber

    for (let i = 0; i < arr.length; i++) {
        if (arr[i] <= missingNumber) {
            missingNumber = arr[i] + 1;
        } else {
            return missingNumber;
        }
    }

    return arr[arr.length - 1] + 1;
}

export const countLikedAndDislikedCards = (groups: Group[]) => {
    let counters = {
        likeCounter: 0,
        dislikeCounter: 0
    }

    groups.forEach(group => {
        group.cards.forEach(card => {
            if (card.grade_status === "liked")
                counters.likeCounter++
            if (card.grade_status === "disliked")
                counters.dislikeCounter++
        })
    })

    return counters
}

export const setGradeCorrectly = (grade: LikeStatus, currentStatus: GradeStatus) => {
    if (grade === 'liked') {
        return  currentStatus === 'liked' ? 'unset' : 'liked'
    } else if (grade === 'disliked') {
        return currentStatus === 'disliked' ? 'unset' : 'disliked'
    }
    return  currentStatus === 'neutral' ? 'unset' : 'neutral'
}
