import {$authHost} from "../../../../axios";
import {SaveCardsIn, SortingStateIn} from "./types";
import {GroupState} from "./reducers/cardSortingReducer/cardCortingReducerTypes";

export const getSortingState = async (project_id: number) => {
    const response = await $authHost.get<SortingStateIn>(`/card_sorting/state?project_id=${project_id}`)
    return response.data
}

export const saveCards = async (cards: SaveCardsIn) => {
    const response = await $authHost.post(`/card_sorting/save_cards`, cards)
    return response.data
}

export const saveSorting = async (group_state: GroupState, project_id: number, isSubmitted: boolean) => {
    // Костыль, убираю тут Draggable свойства, чтобы нормально проходило по типам на бэк
    const groupsWithBin = [...group_state.groups.map(group => ({
        id: group.id,
        name: group.name,
        cards: group.cards.map(card => ({ id: card.id, text: card.text, grade_status: card.grade_status }))
    })), {
        id: -1,
        name: 'bin',
        cards: group_state.bin.map(card => ({ id: card.id, text: card.text, grade_status: card.grade_status }))
    }, {
        id: -2,
        name: 'bank',
        cards: group_state.bank.map(card => ({ id: card.id, text: card.text, grade_status: card.grade_status }))
    }]

    const response = await $authHost.post(`/card_sorting/save_sorting_state`, {
        project_id,
        groups: groupsWithBin,
        state_json: JSON.stringify(group_state),
        is_submitted: isSubmitted
    })
    return response.data
}