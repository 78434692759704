import React, {FormEventHandler, useEffect, useState} from 'react';
// @ts-ignore
import styles from './AuthStyles.module.css'
import {Button, Input} from "../../../system/UX";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslate} from "./translates/useTranslate";
import {checkUser, registerUser} from "./http";
import {getErrorUrl, readJwtPayload} from "../../../../common/utils";
import {useAppDispatch} from "../../../../store/store";
import {appSlice} from "../../../../store/appReducer/appReducer";
import {useToggle} from "../../../../common/hooks";
import {AlertModal} from "../../../system/components";
import {UserRole} from "../../../../store/appReducer/appReducerTypes";
import {Pages} from "../../../../common/types";
import {getLanguage} from "../../../../store/appReducer/ActionCreators";

export const Auth = () => {
    const token = String(useParams().token)
    const project_id = Number(useParams().project_id)
    const dispatch = useAppDispatch()
    const { setUser, setLanguage } = appSlice.actions
    const translate = useTranslate()
    const [name, setName] = useState('')
    const modal = useToggle(false)
    const isPending = useToggle(false)
    const [isCheckUserPending, setIsCheckPending] = useState(true)
    const navigate = useNavigate()

    const setTokenAndNavigateToWork = (data: string) => {
        const payload = readJwtPayload(data)
        const oldToken = localStorage.getItem('token')

        if (oldToken) {
            const oldTokenData = readJwtPayload(oldToken)
            if (oldTokenData.sub !== payload.sub) {
                console.log('local storage cleared')
                localStorage.clear()
            } else {}
            console.log('Local storage NOT cleared')
        } else {
            console.log('old token not exist')
        }

        localStorage.setItem('token', data)

        dispatch(setUser({
            name: payload.name,
            email: payload.sub,
            user_role: payload.user_role,
            client_id: payload.client_id
        }))

        if (payload.user_role === UserRole.SUPERADMIN) {
            navigate(Pages.superadmin_page)
        } else if (payload.user_role === UserRole.ADMIN) {
            navigate(`${Pages.admin}/${payload.client_id}`)
        } else {
            console.log('navigate to project prom auth with project_id = ' + project_id)
            navigate(`${Pages.main}/${project_id}`)
        }

        if (payload.user_role !== UserRole.SUPERADMIN) dispatch(getLanguage())
    }

    const submit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()

        if (name) {
            isPending.toggleValue()
            registerUser(token, name).then(setTokenAndNavigateToWork).catch(e => navigate(getErrorUrl(e.message)))
        } else {
            modal.toggleValue()
        }
    }

    useEffect(() => {
        checkUser(token).then(data => {
            if (data === 'RU' || data === 'EN') {
                dispatch(setLanguage(data))
                setIsCheckPending(false)
            } else {
                setTokenAndNavigateToWork(data)
            }
        }).catch(e => navigate(getErrorUrl(e.message)))
    }, [])

    return (
        <>
            {modal.state && <AlertModal onSubmit={modal.toggleValue} submitText={translate.gotItInputError}>
                {translate.textInputError}
            </AlertModal>}
            {!isCheckUserPending && <form className={styles.authBlock} onSubmit={submit}>
                <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={translate.askNamePlaceholder}
                    label={translate.askNameTittle}
                    labelClassName={styles.label}
                    containerClassName={styles.inputContainer}
                />
                <Button type="submit" isPending={isPending.state}>{translate.send}</Button>
            </form>}
        </>
    );
};