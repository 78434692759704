import React, {FC} from 'react';
// @ts-ignore
import styles from './InstructionBlockStyles.module.css';
import {Button} from "../../UX";

const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

interface InstructionBlockProps {
    title: string
    content: string
    confirm?: {
        handler: () => void
        confirmationText: string
    }
}

export const InstructionBlock:FC<InstructionBlockProps> = (props) => {
    const { title, content, confirm } = props

    return (
        <div className={styles.instructionBlock}>
            <h2 className={styles.instructionTitle}>{title}</h2>
            {isHTML(content) && <p className={styles.instructionContent} dangerouslySetInnerHTML={{__html: content}}/>}
            {!isHTML(content) && <p className={styles.instructionContent}>{content}</p>}
            {confirm && <Button onClick={confirm.handler}>{confirm.confirmationText}</Button>}
        </div>
    );
};