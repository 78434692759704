import React, {FC} from 'react';
// @ts-ignore
import styles from './HyperlinkStyles.module.css'
import classNames from "classnames";
import {Link} from "react-router-dom";

interface LinkProps {
    href: string
    children: string
    className?: string
    style?: {}
    onClick?: () => void
}

export const Hyperlink: FC<LinkProps> = (props) => {
    const { href, children, className, style, onClick } = props

    return (
        <Link to={href} style={style} className={classNames(styles.link, className)} onClick={onClick}>{children}</Link>
    );
};