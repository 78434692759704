import React, {FC} from 'react';
// @ts-ignore
import styles from '../../CardSortingStyles.module.css'
import {BinTrashIcon} from "../icons";
import {CardInBin} from "./CardInBin";
import SimpleBar from "simplebar-react";
import {useTranslate} from "../../translates/useTranslate";
import {useAppSelector} from "../../../../../../store/store";
import {useClickOutsideListenerWithRef} from "../../../../../../common/hooks";

interface BinProps {
    closeHandler: () => void
}

export const Bin: FC<BinProps> = ({ closeHandler }) => {
    const translate = useTranslate()
    const bin = useAppSelector(state => state.cardSortingReducer.group_state.bin)
    const ref = useClickOutsideListenerWithRef(closeHandler)

    return (
        <div className={styles.binContainer} ref={ref}>
            <div className={styles.bin}>
                <div className={styles.binHeading}>
                    <BinTrashIcon/>
                    <h3>{translate.binHeader}</h3>
                </div>
                <div className={styles.scrollableBinCardContainer}>
                    <SimpleBar style={{maxHeight: '100%', width: '100%'}} autoHide={false}>
                        <div className={styles.bankOrBinCardContainer}>
                            {bin && bin.map(card => <CardInBin key={card.id} card={card}/>)}
                        </div>
                    </SimpleBar>
                </div>
            </div>
        </div>
    );
};