import React, {FC} from 'react';

interface LikeIconProps {
    className: string
    isDislike?: boolean
}

export const LikeIcon: FC<LikeIconProps> = (props) => {
    const { className, isDislike = false } = props

    return (
        <svg
            width="0.6rem"
            height="0.6rem"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg" className={className}
            style={{ transform: isDislike ? 'rotate(180deg)' : 'unset' }}
        >
            <path d="M0 8.5C-2.93527e-09 8.30302 0.0387987 8.10796 0.114181 7.92597C0.189563 7.74399 0.300052 7.57863 0.43934 7.43934C0.578628 7.30005 0.743986 7.18956 0.925975 7.11418C1.10796 7.0388 1.30302 7 1.5 7C1.69698 7 1.89204 7.0388 2.07403 7.11418C2.25601 7.18956 2.42137 7.30005 2.56066 7.43934C2.69995 7.57863 2.81044 7.74399 2.88582 7.92597C2.9612 8.10796 3 8.30302 3 8.5V14.5C3 14.8978 2.84196 15.2794 2.56066 15.5607C2.27936 15.842 1.89782 16 1.5 16C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 5.92805e-09 14.8978 0 14.5V8.5ZM4 8.333V13.763C3.99983 14.1347 4.10322 14.499 4.29858 14.8152C4.49394 15.1314 4.77353 15.3869 5.106 15.553L5.156 15.578C5.71089 15.8553 6.32267 15.9998 6.943 16H12.359C12.8215 16.0002 13.2698 15.84 13.6276 15.5469C13.9853 15.2537 14.2303 14.8456 14.321 14.392L15.521 8.392C15.579 8.10187 15.5719 7.80249 15.5002 7.51544C15.4285 7.22839 15.294 6.96082 15.1065 6.73201C14.9189 6.50321 14.6829 6.31887 14.4155 6.19229C14.148 6.0657 13.8559 6.00003 13.56 6H10V2C10 1.46957 9.78929 0.960859 9.41421 0.585786C9.03914 0.210714 8.53043 0 8 0C7.73478 0 7.48043 0.105357 7.29289 0.292893C7.10536 0.48043 7 0.734784 7 1V1.667C7 2.53248 6.71929 3.37462 6.2 4.067L4.8 5.933C4.28071 6.62538 4 7.46752 4 8.333Z"/>
        </svg>
    );
};