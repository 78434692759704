import React, {FC, ReactElement} from 'react';
//@ts-ignore
import styles from './IconButtonStyles.module.css'
import classNames from "classnames";

interface IconButtonProps {
    onClick?: (e: any) => void
    children: ReactElement
    isActive?: boolean
    className?: string
    isSmall?: boolean
}

export const IconButton: FC<IconButtonProps> = (props) => {
    const {
        children,
        onClick,
        className,
        isActive = false,
        isSmall = false
    } = props

    return (
        <div
            className={classNames(
                styles.iconButton,
                isSmall ? styles.iconButtonSmall : styles.iconButtonNormal,
                className,
                isActive && styles.activeIconButton
            )}
            onClick={onClick}
        >
            {children}
        </div>
    );
};