import React from 'react';
import CardSorting from "../../project/modules/main/CardSorting/CardSorting";
// @ts-ignore
import styles from '../CenterLayout.module.css'

export const CardSortingPage = () => {
    return (
        <div className={styles.centerLayout}>
            <CardSorting />
        </div>
    );
};