// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InstructionBlockStyles_instructionBlock__sY0ug {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 2rem\n}\n\n.InstructionBlockStyles_instructionTitle__5s-1F {\n    text-align: center;\n    font-size: 1.5rem;\n    font-weight: bold;\n}\n\n.InstructionBlockStyles_instructionContent__MzRA7 {\n    font-size: 1rem;\n    max-width: 50rem;\n}", "",{"version":3,"sources":["webpack://./src/project/system/components/InstructionBlock/InstructionBlockStyles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB;AACJ;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".instructionBlock {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 2rem\n}\n\n.instructionTitle {\n    text-align: center;\n    font-size: 1.5rem;\n    font-weight: bold;\n}\n\n.instructionContent {\n    font-size: 1rem;\n    max-width: 50rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instructionBlock": "InstructionBlockStyles_instructionBlock__sY0ug",
	"instructionTitle": "InstructionBlockStyles_instructionTitle__5s-1F",
	"instructionContent": "InstructionBlockStyles_instructionContent__MzRA7"
};
export default ___CSS_LOADER_EXPORT___;
