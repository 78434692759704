import {Translates} from "./translates";

export const ruTranslates: Translates = {
    gotItButton: 'Понятно',
    confirmButton: 'Да',
    cancelButton: 'Нет',
    userManager: {
        userSettingsTitle: 'Настройки пользователя',
        confirmSaveUserSettings: 'Уверены, что хотите сохранить для пользователя выбранные проекты и данные?',
        email: 'Логин',
        name: 'Имя',
        isAdmin: 'Является ли пользователь админом',
        userProjects: 'Проекты, в которых участвует пользователь:',
        admin: 'админ',
        admins: 'Администраторы',
        userManagementTitle: 'Управление пользователями',
        createUserError: 'Такой пользователь уже существует',
        confirmDeleteUserMessage: 'Вы уверены, что хотите удалить данного пользователя?',
        notStartedStatus: 'not started',
        deleteUserHint: 'Удалить пользователя',
        openSettingsHint: 'Открыть настройки пользователя',
        addUserLabel: 'Введите логин нового пользователя',
        addUserPlaceholder: 'Добавить пользователя',
        addUserButton: 'Добавить',
        notAuthStatus: 'Не авторизован',
        downloadXLSX: 'Скачать результаты в xlsx',
        searchByNameOrLogin: 'Поиск по имени или логину',
        statusFilter: 'Фильтр по статусам:',
        projectFilter: 'Фильтр по проектам:',
        clickToCopyLinkHint: 'Кликните, чтобы скопировать в буфер обмена авторизационную ссылку, ведущую на данный проект',
        linkCopied: 'Ссылка скопирована',
    },
    sortingSettings: {
        errors: {
            emptyQuestion: 'Вы не ввели вопрос',
            emptyMinCardsPerQuestion: 'Вы не ввели минимальное количество карточек',
            emptyMaxCardsPerQuestion: 'Вы не ввели максимальное количество карточек',
            emptyLikeHint: 'Вы не ввели подсказку для кнопки "лайк"',
            emptyDislikeHint: 'Вы не ввели подсказку для кнопки "дизлайк"',
            emptyLikeAllHint: 'Вы не ввели подсказку для кнопки "лайкнуть все"',
            emptyNeutralGradeHint: 'Вы не ввели подсказку для кнопки "нейтральная оценка"',
            emptyDislikeAllHint: 'Вы не ввели подсказку для кнопки "дизлайкнуть все"',
            emptyInstructionTitle: 'Вы не ввели заголовок инструкции',
            emptyInstructionText: 'Вы не ввели текст инструкции',
            emptySortingInstructionText: 'Вы не ввели текст инструкции для сортировки',
            emptySortingInstructionTitle: 'Вы не ввели заголовок инструкции для сортировки'
        },
        cardTrashIconHint: 'Удалить карточку',
        copyCardIconHint: 'Копировать карточку',
        cardEditorConfirmDelete: 'Вы уверены, что хотите удалить эту карточку?',
        cardEditorHeader: 'Пре-модерация ответов',
        cardEditorPlaceholder: 'Нет созданных карточек',
        pressNameToSetUp: 'Нажмите на название проекта, чтобы задать настройки',
        projectsHeader: 'Проекты',
        passSort: 'Пройти',
        confirmStartSorting: 'Вы уверены, что хотите перейти к этапу сортировки? После переключения этапа не будет возможности перейти обратно к этапу сбора карточек.',
        confirmDeleteProject: 'Вы уверены, что хотите удалить данный проект?',
        addButton: 'Добавить',
        deleteProjectButton: 'Удалить проект',
        startSortingButton: 'Запустить сортировку',
        preModerationButton: 'Пре-модерация ответов',
        status: 'Статус: ',
        inputProjectLabel: 'Введите имя нового проекта',
        inputProjectPlaceholder: 'Введите имя...',
        settingsTitle: 'Настройки сортировки',
        settingsSaved: 'Настройки проекта сохранены',
        inputQuestionLabel: 'Текст вопроса',
        inputQuestionPlaceholder: 'Введите вопрос...',
        inputMinCardsLabel: 'Минимальное количество карточек',
        inputMaxCardsLabel: 'Максимальное количество карточек',
        inputLikeHintLabel: 'Подсказка для кнопки "лайк"',
        inputLikeHintPlaceholder: 'Введите подсказку для кнопки "лайк"...',
        inputDislikeHintLabel: 'Подсказка для кнопки "дизлайк"',
        inputDislikeHintPlaceholder: 'Введите подсказку для кнопки "дизлайк"...',
        inputLikeLimiterHintLabel: 'Лимит на количество лайков в процентах (оставьте 0, если не хотите ставить лимит)',
        inputLikeLimiterPlaceholder: 'Введите лимит на количество лайков...',
        inputNeutralGradeHintLabel: 'Подсказка для кнопки "нейтральная оценка"',
        inputNeutralGradeHintPlaceholder: 'Введите подсказку для кнопки "нейтральная оценка"...',
        inputDislikeLimiterHintLabel: 'Лимит на количество дизлайков в процентах (оставьте 0, если не хотите ставить лимит)',
        inputDislikeLimiterPlaceholder: 'Введите лимит на количество дизлайков...',
        inputInstructionTitleLabel: 'Заголовок инструкции',
        inputInstructionTitlePlaceholder: 'Введите текст заголовка...',
        inputInstructionTextLabel: 'Текст инструкции',
        inputInstructionTextPlaceholder: 'Введите текст инструкции (допустимо использование тэга <br>)',
        inputSortingInstructionTitleLabel: 'Заголовок инструкции для сортировки',
        inputSortingInstructionTitlePlaceholder: 'Введите текст заголовка для сортировки...',
        inputSortingInstructionTextLabel: 'Текст инструкции для сортировки',
        inputSortingInstructionTextPlaceholder: 'Введите текст инструкции для сортировки (допустимо использование тэга <br>)',
        inputMobileSortingInstructionTextLabel: 'Текст инструкции для мобильной сортировки',
        inputMobileSortingInstructionTextPlaceholder: 'Введите текст инструкции для мобильной сортировки (допустимо использование тэга <br>)',
        saveButton: 'Сохранить'
    }
}